import mime from "mime";
import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ImageUploading, { ImageListType } from "react-images-uploading";

import { Box, Icon, Image } from "@chakra-ui/react";
import { makeFullUrlApiCall } from "../../common/axios";

function getFileMimeType(file: File): string {
  return file.type || (mime.getType(file.name) as string);
}

export const useImageUploader: () => Props = () => {
  const [images, setImages] = useState<ImageListType>([]);

  const uploadImage: () => Promise<string> = async () => {
    const signedUrlResponse = await makeFullUrlApiCall(
      `s3/sign?objectName=${
        images[0].file?.name ?? "unknown"
      }&contentType=${encodeURIComponent(
        getFileMimeType(images[0].file as File)
      )}&path=${encodeURIComponent("images/")}`
    );

    if (signedUrlResponse.status !== 200) {
      return "";
    }

    const signedUrlResponseData = (signedUrlResponse.data as unknown) as {
      signedUrl: string;
      filename: string;
    };

    const response = await fetch(signedUrlResponseData.signedUrl, {
      method: "PUT",
      body: images[0].file,
    });

    if (response.status !== 200) {
      console.error("Failed to upload", response);
      return "";
    }

    return signedUrlResponseData.filename;
  };

  return {
    setImages,
    images,
    uploadImage,
  };
};

interface Props {
  setImages: (images: ImageListType) => void;
  images: ImageListType;
  uploadImage: () => Promise<string>;
  dashedBorder?: boolean;
  circular?: boolean;
  placeholderImageUrl?: string;
  restrictImageSize?: boolean;
}

const ImageUploader: React.FC<Props> = ({ images, setImages }) => {
  const onChange = (imageList: ImageListType) => {
    setImages(imageList);
  };

  const hasUploadedFile = images.length > 0;

  return (
    <Box position="relative" height="100%" mr="40px" flexShrink={0}>
      <Image
        src="/upload-background.png"
        alt="Background image"
        minH="100%"
        minW="fit-content"
      />
      <Box
        position="absolute"
        top={hasUploadedFile ? "27%" : "40%"}
        left={hasUploadedFile ? "20%" : "27%"}
      >
        <ImageUploading
          value={images}
          onChange={onChange}
          dataURLKey="data_url"
        >
          {({ imageList, onImageUpload, dragProps, onImageRemove }) => (
            <Box position="relative">
              <Image
                src={
                  hasUploadedFile
                    ? (imageList[0]["data_url"] as string)
                    : "/upload.png"
                }
                alt="File uploader image"
                w={hasUploadedFile ? "220px" : "160px"}
                h={hasUploadedFile ? "220px" : "70px"}
                transition="transform 500ms ease-in-out"
                onClick={onImageUpload}
                cursor="pointer"
                objectFit="cover"
                objectPosition="50% 50%"
                borderRadius={hasUploadedFile ? "100%" : "0px"}
                {...dragProps}
                zIndex={2}
              />
              {hasUploadedFile && (
                <Icon
                  as={AiOutlineCloseCircle}
                  color="red"
                  position="absolute"
                  top={0}
                  right="20px"
                  onClick={() => onImageRemove(0)}
                  cursor="pointer"
                />
              )}
            </Box>
          )}
        </ImageUploading>
      </Box>
    </Box>
  );
};

export const SimpleImageUploader: React.FC<Props> = ({
  images,
  setImages,
  dashedBorder,
  circular,
  placeholderImageUrl,
  restrictImageSize,
}) => {
  const onChange = (imageList: ImageListType) => {
    setImages(imageList);
  };

  const hasUploadedFile = images.length > 0;

  return (
    <Box
      position="relative"
      height="100%"
      flexShrink={0}
      border={dashedBorder && !hasUploadedFile ? "3px solid black" : ""}
      borderStyle={dashedBorder && !hasUploadedFile ? "dashed" : "none"}
      borderRadius="10px"
      p="10px"
      pt={!hasUploadedFile ? "20px" : 0}
    >
      <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
        {({ imageList, onImageUpload, dragProps, onImageRemove }) => (
          <Box position="relative">
            <Image
              src={
                hasUploadedFile
                  ? (imageList[0]["data_url"] as string)
                  : placeholderImageUrl ?? "/upload.png"
              }
              alt="File uploader image"
              maxW="300px"
              maxH="300px"
              transition="transform 500ms ease-in-out"
              onClick={onImageUpload}
              cursor="pointer"
              objectFit={restrictImageSize ? "contain" : "cover"}
              objectPosition="50% 50%"
              borderRadius={hasUploadedFile && circular ? "100%" : "0px"}
              {...dragProps}
              zIndex={2}
            />
            {hasUploadedFile && (
              <Icon
                as={AiOutlineCloseCircle}
                color="red"
                position="absolute"
                top={circular ? 0 : "20px"}
                right="20px"
                onClick={() => onImageRemove(0)}
                cursor="pointer"
              />
            )}
          </Box>
        )}
      </ImageUploading>
    </Box>
  );
};

export default ImageUploader;
