import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Table,
  TableCaption,
  Tag,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DragEndEvent,
  closestCenter,
  DndContext,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  sortableKeyboardCoordinates,
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS, reorderMilestones } from "common/api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { UserContext } from "contexts/UserContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { AgeGroup, IAgeGroup } from "views/Dashboard/LearningContent/constants";
import { LearningMilestone } from "../interfaces";
import EditMilestoneModal from "./EditMilestoneModal";
import MilestoneTableRow from "./MilestoneTableRow";

type Mode = "edit" | "reorder";

const defaultItems: Record<IAgeGroup, string[]> = {
  [AgeGroup.twoThree]: [],
  [AgeGroup.threeFour]: [],
  [AgeGroup.fourFive]: [],
  [AgeGroup.fiveSix]: [],
  [AgeGroup.sixPlus]: [],
};

const MilestoneTable: React.FC = () => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const toast = useToast();
  const textColor = useColorModeValue("gray.700", "white");
  const disclosureProps = useDisclosure();

  const { selectedCurriculum } = useContext(UserContext);
  const [milestones, setMilestones] = useState<LearningMilestone[]>([]);
  const [milestoneToEdit, setMilestoneToEdit] = useState<LearningMilestone>();

  const [mode, setMode] = useState<Mode>("edit");
  const [items, setItems] = useState<Record<IAgeGroup, string[]>>(defaultItems);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (!selectedCurriculum) {
      return;
    }

    const milestones = selectedCurriculum.milestones.sort(
      (a, b) => a.orderNum - b.orderNum
    );
    setMilestones(milestones);

    let items: Record<IAgeGroup, string[]> = defaultItems;
    Object.values(AgeGroup).forEach((ag) => {
      items[ag] = milestones
        .filter((m) => m.ageGroups.includes(ag))
        .map((m) => m.milestoneID);
    });
    setItems(items);
  }, [selectedCurriculum]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((items) => {
        let newMilestoneItems: string[] = [];
        let affectedAgeGroup: IAgeGroup = AgeGroup.twoThree;
        for (const ag of Object.values(AgeGroup)) {
          const milestoneItems = items[ag];
          const oldIndex = milestoneItems.indexOf(active.id as string);

          if (oldIndex === -1) {
            continue;
          }
          affectedAgeGroup = ag;
          const newIndex = milestoneItems.indexOf(over.id as string);
          newMilestoneItems = arrayMove(milestoneItems, oldIndex, newIndex);
          break;
        }

        return {
          ...items,
          [affectedAgeGroup]: newMilestoneItems,
        };
      });
      setMilestones((milestones) => {
        const oldIndex = milestones.findIndex(
          (m) => m.milestoneID === (active.id as string)
        );
        const newIndex = milestones.findIndex(
          (m) => m.milestoneID === (over.id as string)
        );

        return arrayMove(milestones, oldIndex, newIndex);
      });
    }
  }

  const curriculumID = selectedCurriculum && selectedCurriculum.curriculumID;

  const queryClient = useQueryClient();
  const reorderMilestoneMutation = useMutation(reorderMilestones, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.curriculums]);
    },
  });

  const reorder = useCallback(
    async (
      curriculumID: string,
      milestoneIDs: string[],
      informUser: boolean = true
    ) => {
      if (!curriculumID) {
        return;
      }

      setIsReorderingOrDeleting(true);
      const response = await reorderMilestoneMutation.mutateAsync({
        milestoneIDs,
        curriculumID,
      });

      if (response.status !== 204) {
        toast({
          title: "Error",
          description: `Could not reorder milestones`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }

      if (informUser) {
        toast({
          title: "Success",
          description: `Milestones successfully reordered`,
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      }
      setIsReorderingOrDeleting(false);
    },
    [reorderMilestoneMutation, toast]
  );

  const [deleteMilestone, setDeleteMilestone] = useState<
    (() => Promise<void>) | null
  >(null);
  const [isReorderingOrDeleting, setIsReorderingOrDeleting] = useState(false);

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <EditMilestoneModal
        disclosureProps={disclosureProps}
        editMode={!!milestoneToEdit}
        milestone={milestoneToEdit}
        reorder={reorder}
      />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => setDeleteMilestone(null)}
        isOpen={!!deleteMilestone}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Milestone
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setDeleteMilestone(null)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  setIsReorderingOrDeleting(true);
                  deleteMilestone && (await deleteMilestone());
                  setDeleteMilestone(null);
                  setIsReorderingOrDeleting(false);
                }}
                ml={3}
                isLoading={isReorderingOrDeleting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <CardHeader p="6px 0px 22px 0px">
        <HStack w="100%" pr="20px" spacing={10}>
          <VStack spacing={1} alignItems="flex-start">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Learning Milestones
            </Text>
            <Text fontSize="sm" color="gray.500">
              Ordered in curriculum order
            </Text>
          </VStack>
          <Spacer />
          <RadioGroup value={mode} onChange={(m) => setMode(m as Mode)}>
            <Stack direction="row">
              <Radio value="edit">Edit</Radio>
              <Radio value="reorder">Reorder</Radio>
            </Stack>
          </RadioGroup>
          <Button
            onClick={() => {
              disclosureProps.onOpen();
              setMilestoneToEdit(undefined);
            }}
          >
            Create
          </Button>
        </HStack>
      </CardHeader>
      <CardBody>
        <VStack spacing={20} w="100%">
          {Object.values(AgeGroup).map((ag) => (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
              key={ag}
            >
              <SortableContext
                items={items[ag]}
                strategy={verticalListSortingStrategy}
              >
                <VStack w="100%">
                  <Table variant="simple" color={textColor}>
                    <TableCaption
                      placement="top"
                      fontWeight="bold"
                      fontSize="md"
                      mb={4}
                    >
                      <HStack spacing={4}>
                        <Divider />
                        <Tag
                          p="6px 10px"
                          borderRadius="10px"
                          colorScheme="teal"
                          w="140px"
                          justifyContent="center"
                          variant="solid"
                        >
                          {ag}
                        </Tag>
                        <Divider />
                      </HStack>
                    </TableCaption>
                    <Thead>
                      <Tr my=".8rem" pl="0px" color="gray.400">
                        {[
                          "Name",
                          "Description",
                          "Age Groups",
                          "Development Areas",
                          "Learning Content",
                          "",
                        ].map((caption, idx) => {
                          return (
                            <Th
                              color="gray.400"
                              key={idx}
                              ps={idx === 0 ? "0px" : undefined}
                            >
                              <HStack
                                justifyContent="center"
                                textAlign="center"
                              >
                                <Text>{caption}</Text>
                              </HStack>
                            </Th>
                          );
                        })}
                      </Tr>
                    </Thead>

                    <Tbody>
                      {milestones
                        .filter((m) => m.ageGroups.includes(ag))
                        .map((row) => {
                          return (
                            <MilestoneTableRow
                              key={row.milestoneID}
                              milestone={row}
                              onClick={() => {
                                disclosureProps.onOpen();
                                setMilestoneToEdit(row);
                              }}
                              isReordering={mode === "reorder"}
                              setDeleteMilestone={setDeleteMilestone}
                            />
                          );
                        })}
                    </Tbody>
                  </Table>
                  {milestones.filter((m) => m.ageGroups.includes(ag)).length ===
                    0 && (
                    <Text color="red.400" py={8}>
                      No content
                    </Text>
                  )}
                </VStack>
              </SortableContext>
            </DndContext>
          ))}
        </VStack>
      </CardBody>
      {mode === "reorder" && (
        <Button
          pos="fixed"
          bottom={10}
          right={10}
          leftIcon={<Icon as={FaCheck} />}
          colorScheme="teal"
          variant="solid"
          onClick={() =>
            reorder(
              curriculumID ?? "",
              milestones.map((m) => m.milestoneID)
            )
          }
          isLoading={isReorderingOrDeleting}
        >
          Reorder
        </Button>
      )}
    </Card>
  );
};

export default MilestoneTable;
