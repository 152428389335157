import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  UseDisclosureReturn,
  useToast,
  Link,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addMilestone,
  getActivities,
  QUERY_KEYS,
  updateMilestone,
} from "common/api";
import MultipleCategoryFormField from "components/MultipleCategoryFormField";
import { LearningMilestone } from "../interfaces";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import { BsBoxArrowInRight } from "react-icons/bs";
import { UserContext } from "contexts/UserContext";
import {
  Activity,
  AgeGroup,
  DevelopmentArea,
  IAgeGroup,
  IDevelopmentArea,
} from "views/Dashboard/LearningContent/constants";

function LearningContentDropdownItem({
  label,
  value,
}: {
  label: string;
  value: string;
}) {
  return (
    <HStack>
      <Text>{label}</Text>
      <Link
        href={`https://preschool.education/activity/${value}`}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          icon={<Icon as={BsBoxArrowInRight} ml="-5px" />}
          aria-label="Go to content"
          colorScheme="teal"
          size="sm"
        />
      </Link>
    </HStack>
  );
}

interface Props {
  disclosureProps: UseDisclosureReturn;
  milestone?: LearningMilestone;
  editMode: boolean;
  reorder: (
    curriculumID: string,
    milestoneIDs: string[],
    informUser?: boolean
  ) => Promise<void>;
}

type IFormInputs = Omit<LearningMilestone, "milestoneID" | "ageGroups"> & {
  ageGroup: IAgeGroup;
  developmentAreas: IDevelopmentArea[];
};

const schema = yup
  .object({
    name: yup
      .string()
      .required("Please provide a name for this milestone")
      .trim(),
    description: yup.string().trim(),
    ageGroup: yup.string(),
    developmentAreas: yup.array(yup.string()).default([]),
    activities: yup.array(yup.string()).default([]),
    orderNum: yup.number().positive(),
  })
  .required();

export interface Item {
  label: string;
  value: string;
}

const EditMilestoneModal: React.FC<Props> = ({
  disclosureProps,
  milestone: milestoneToEdit,
  editMode,
}) => {
  const { selectedCurriculum } = useContext(UserContext);
  const [createAnother, setCreateAnother] = useState(false);
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      developmentAreas: [],
      activities: [],
    },
  });

  const activityQuery = useQuery([QUERY_KEYS.activities], getActivities);

  useEffect(() => {
    if (!milestoneToEdit) {
      reset();
      return;
    }

    setValue("name", milestoneToEdit.name);
    // @ts-ignore
    setValue("developmentAreas", milestoneToEdit.developmentAreas);
    // @ts-ignore
    setValue("ageGroup", milestoneToEdit.ageGroups[0]);
    setValue("description", milestoneToEdit.description);
    setValue("activities", milestoneToEdit.activities);
    setValue("orderNum", milestoneToEdit.orderNum);
  }, [milestoneToEdit, reset, setValue]);

  const queryClient = useQueryClient();
  const addMilestoneMutation = useMutation(addMilestone, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.curriculums]);
    },
  });
  const updateMilestoneMutation = useMutation(updateMilestone, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.curriculums]);
    },
  });

  const watchAgeGroup = watch("ageGroup");
  const watchDevelopmentAreas = watch("developmentAreas");
  const watchActivities = watch("activities");

  const createMilestone: (
    data: Parameters<typeof addMilestone>[0]
  ) => Promise<LearningMilestone | null> = useCallback(
    async (data) => {
      const response = await addMilestoneMutation.mutateAsync(data);

      if (response.status === 201) {
        reset();

        if (!createAnother) {
          disclosureProps.onClose();
        }
      } else {
        return null;
      }

      return response.data.data as LearningMilestone;
    },
    [addMilestoneMutation, createAnother, disclosureProps, reset]
  );

  const editMilestone = useCallback(
    async (data: Parameters<typeof updateMilestone>[0]) => {
      const response = await updateMilestoneMutation.mutateAsync(data);

      if (response.status === 204) {
        toast({
          title: "Success",
          description: `You've successfully edited ${data.name}`,
          status: "success",
          duration: 4000,
          isClosable: true,
        });

        reset();

        disclosureProps.onClose();
      } else {
        toast({
          title: "Error",
          description: `Something unexpected happened\n${response.data.errors}`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    },
    [disclosureProps, reset, toast, updateMilestoneMutation]
  );

  const onSubmit = async (data: IFormInputs) => {
    if (!selectedCurriculum) {
      return;
    }

    const selectedCurriculumMilestones = selectedCurriculum.milestones;

    if (milestoneToEdit && editMode) {
      const editedMilestone: LearningMilestone = {
        ...data,
        ageGroups: [data.ageGroup],
        milestoneID: milestoneToEdit.milestoneID,
      };

      await editMilestone({
        ...editedMilestone,
        curriculumID: selectedCurriculum.curriculumID,
      });
    } else {
      const milestoneToAdd: Parameters<typeof addMilestone>[0] = {
        ...data,
        ageGroups: [data.ageGroup],
        curriculumID: selectedCurriculum.curriculumID,
        activityIDs: data.activities,
        orderNum: selectedCurriculumMilestones.length + 1,
      };

      const addedMilestone = await createMilestone(milestoneToAdd);
      if (!addedMilestone) {
        toast({
          title: "Error",
          description: `Something unexpected happened`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }

      toast({
        title: "Success",
        description: "Successfully added milestone",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={disclosureProps.isOpen}
      onClose={disclosureProps.onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent textAlign="center" py="20px" fontWeight="bold">
        <ModalHeader>
          {editMode && milestoneToEdit
            ? `Edit ${milestoneToEdit.name}`
            : "Add a milestone"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, (err) => console.error(err))}>
            <FormControl isRequired isInvalid={!!errors.name} my="40px">
              <FormLabel htmlFor="name">Milestone name</FormLabel>
              <Input id="name" placeholder="Name" {...register("name")} />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.description} my="40px">
              <FormLabel htmlFor="description">Description</FormLabel>
              <Textarea
                id="description"
                placeholder="Description"
                {...register("description")}
              />
            </FormControl>

            <MultipleCategoryFormField
              //@ts-ignore
              error={errors.ageGroup}
              label="Age Groups"
              options={AgeGroup}
              watchValue={watchAgeGroup}
              setValue={setValue}
              register={register}
              fieldKey="ageGroup"
              buttonColor="primary.red"
              singleOption
            />

            <MultipleCategoryFormField
              //@ts-ignore
              error={errors.developmentAreas}
              label="Developmental Areas"
              options={DevelopmentArea}
              watchValue={watchDevelopmentAreas}
              setValue={setValue}
              fieldKey="developmentAreas"
              buttonColor="primary.red"
              register={register}
            />

            <CUIAutoComplete
              label="Learning Content"
              placeholder="Select all learning content required to complete this milestone"
              disableCreateItem
              items={(activityQuery.data ?? ([] as Activity[])).map((item) => {
                return {
                  value: String(item.id),
                  label: item.name,
                };
              })}
              selectedItems={watchActivities.map((id) => {
                const activity = activityQuery.data?.find((a) => a.id === id);
                return {
                  value: String(id),
                  label: activity?.name ?? "",
                };
              })}
              onSelectedItemsChange={(changes) =>
                setValue(
                  "activities",
                  changes.selectedItems?.map((items) => Number(items.value)) ??
                    []
                )
              }
              tagStyleProps={{
                bgColor: "lightgreen",
                h: "30px",
                mb: "10px",
                ml: 2,
              }}
              itemRenderer={LearningContentDropdownItem}
              labelStyleProps={{ m: 0 }}
            />

            <Flex mt="50px" mb="15px" justifyContent="flex-end">
              <Button
                colorScheme="red"
                borderRadius="23px"
                p="14px 32px"
                maxW="170px"
                boxShadow="lg"
                onClick={() => reset()}
              >
                Clear
              </Button>
              {!editMode && (
                <FormControl
                  display="flex"
                  flexDir="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <FormLabel mr="30px" mb="0">
                    Create another?
                  </FormLabel>
                  <Checkbox
                    id="createAnother"
                    size="lg"
                    colorScheme="green"
                    isChecked={createAnother}
                    onChange={(event) => setCreateAnother(event.target.checked)}
                  />
                </FormControl>
              )}

              <Button
                borderRadius="23px"
                p="14px 32px"
                maxW="170px"
                ml="30px"
                boxShadow="lg"
                type="submit"
                isLoading={isSubmitting}
                colorScheme="blue"
              >
                {editMode ? "Edit" : "Create"}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditMilestoneModal;
