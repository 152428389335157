/* eslint-disable camelcase */
export interface User {
  userId: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  gender?: string;
  phone?: string;
  profileImg?: string;
  socialLoginType: ISocialLoginType;
  role: IUserRole;
  usageIntention: string;
  preparationTime: string;
  confidenceLevel: string;
  parentingTeachingStyle: string;
  parentingTeachingStyleKeywords: string[];
  dateJoined: Date;
}

export const UserRole = {
  admin: "Admin",
  parent: "Parent",
  educator: "Educator",
} as const;

export type IUserRole = typeof UserRole[keyof typeof UserRole];

export const Sex = {
  male: "Male",
  female: "Female",
} as const;

export type ISex = typeof Sex[keyof typeof Sex];

export const SocialLoginType = {
  facebook: "Facebook",
  google: "Google",
  none: "None",
} as const;

export type ISocialLoginType = typeof SocialLoginType[keyof typeof SocialLoginType];

export interface JWTToken {
  token: string;
  expiresIn: string;
}

export interface AuthTokens {
  accessToken: JWTToken;
}
