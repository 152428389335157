import {
  Center,
  HStack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import ChildProfileImageRenderer from "components/ChildProfileImageRenderer";
import { SocialLoginType, User } from "types/users";

const AdminManagementTableRow: React.FC<{
  admin: User;
  actionButton: React.ReactNode;
  showProfilePic?: boolean;
}> = ({ admin, actionButton, showProfilePic = true }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Tr>
      <Td pl="0px">
        <HStack spacing={8} py=".8rem" minWidth="100%" flexWrap="nowrap">
          {showProfilePic && (
            <ChildProfileImageRenderer
              profileImg={admin.profileImg}
              gender={admin.gender}
            />
          )}
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {!admin.firstName && !admin.lastName
              ? "-"
              : `${admin.firstName} ${admin.lastName}`}
          </Text>
        </HStack>
      </Td>

      <Td>
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          minWidth="100%"
          textAlign="center"
        >
          {admin.email}
        </Text>
      </Td>

      <Td>
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          minWidth="100%"
          textAlign="center"
        >
          {admin.socialLoginType === SocialLoginType.none
            ? "Native"
            : admin.socialLoginType}
        </Text>
      </Td>

      <Td>
        <Center>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {admin.role}
          </Text>
        </Center>
      </Td>

      <Td p={0}>{actionButton}</Td>
    </Tr>
  );
};

export default AdminManagementTableRow;
