import React, { useCallback, useEffect, useState } from "react";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  CircularProgress,
  Divider,
  Editable,
  EditablePreview,
  HStack,
  Input,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  UseDisclosureReturn,
  VStack,
  SimpleGrid,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  EditableTextarea,
  useToast,
  Link,
  Heading,
  Box,
} from "@chakra-ui/react";

import {
  ChildProfile,
  MilestoneProgressTracker,
  ProgressTracker,
} from "../interfaces";
import { baseURL, makeFullUrlApiCall } from "common/axios";
import {
  Curriculum,
  LearningMilestone,
} from "views/Dashboard/Milestones/interfaces";
import { useQuery } from "@tanstack/react-query";
import { getCurriculums, QUERY_KEYS } from "common/api";
import EditableControls from "components/EditableControls";
import moment from "moment";
import { NavLink } from "react-router-dom";
import LikeDislikeButtonGroup from "components/LikeDislikeButtonGroup";

interface Props {
  disclosureProps: UseDisclosureReturn;
  childProfile?: ChildProfile;
}

const FeedbackModal: React.FC<Props> = ({ disclosureProps, childProfile }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [milestoneProgressTrackers, setMilestoneProgressTrackers] = useState<
    Array<MilestoneProgressTracker & LearningMilestone>
  >([]);
  const [progressTrackers, setProgressTrackers] = useState<ProgressTracker[]>(
    []
  );
  const toast = useToast();

  const milestoneQuery = useQuery([QUERY_KEYS.curriculums], getCurriculums);

  useEffect(() => {
    async function init() {
      if (!childProfile || !milestoneQuery.data) {
        return;
      }
      setIsFetching(true);
      const response = await makeFullUrlApiCall(
        `progresstracker/milestoneprogresstracker?childID=${childProfile.childID}`
      );

      if (response.status !== 200) {
        setIsFetching(false);
        return;
      }

      const { milestoneProgressTrackers, progressTrackers } = response.data
        .data as {
        progressTrackers: ProgressTracker[];
        milestoneProgressTrackers: MilestoneProgressTracker[];
      };

      setProgressTrackers(progressTrackers);
      const curriculums = milestoneQuery.data.reduce((acc, c) => {
        return {
          ...acc,
          [c.curriculumID]: c,
        };
      }, {} as Record<string, Curriculum>);

      const matchedTrackers = milestoneProgressTrackers
        .map((mpt) => {
          console.log(mpt);

          const milestone = curriculums[mpt.curriculumID].milestones.find(
            (m) => m.milestoneID === mpt.milestoneID
          );
          if (!milestone) {
            console.error(`Unable to find milestone ${mpt.milestoneID}`);
            console.error(mpt);
            return null;
          }
          return {
            ...mpt,
            ...milestone,
          };
        })
        .filter((m): m is MilestoneProgressTracker & LearningMilestone => !!m)
        .sort((a, b) => {
          if (a.dateOfCompletion && b.dateOfCompletion) {
            return 0;
          } else if (b.dateOfCompletion) {
            return 1;
          } else {
            return -1;
          }
        });

      setMilestoneProgressTrackers(matchedTrackers);
      setIsFetching(false);
    }

    void init();
  }, [childProfile, milestoneQuery.data]);

  const submitFeedback = useCallback(
    async (mpt: MilestoneProgressTracker, feedback: string) => {
      const response = await makeFullUrlApiCall(
        "progresstracker/milestoneprogresstracker",
        {
          data: {
            milestoneProgressTrackerID: mpt.milestoneProgressTrackerID,
            expertFeedback: feedback,
          },
        },
        "patch"
      );
      if (response.status !== 204) {
        toast({
          title: "Error",
          description: `Something unexpected happened`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }

      toast({
        title: "Success",
        description: "Successfully submitted feedback",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    [toast]
  );

  return (
    <Modal
      isOpen={disclosureProps.isOpen}
      onClose={disclosureProps.onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent textAlign="center" py="20px">
        <ModalHeader>Feedback</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isFetching ? (
            <CircularProgress isIndeterminate />
          ) : (
            <VStack>
              {milestoneQuery.data &&
                milestoneQuery.data.map((c) => (
                  <>
                    <HStack spacing={4} w="100%" mb={4}>
                      <Divider />
                      <Tag
                        p="6px 40px"
                        borderRadius="10px"
                        colorScheme="teal"
                        justifyContent="center"
                        variant="solid"
                      >
                        {c.name}
                      </Tag>
                      <Divider />
                    </HStack>
                    <Accordion>
                      {milestoneProgressTrackers
                        .filter((mpt) => mpt.curriculumID === c.curriculumID)
                        .map((mpt) => (
                          <AccordionItem key={mpt.milestoneProgressTrackerID}>
                            <AccordionButton>
                              <SimpleGrid
                                gridTemplateColumns="3fr 2fr"
                                columnGap={5}
                                w="100%"
                              >
                                <Text>{mpt.name}</Text>

                                <HStack>
                                  <Tag
                                    colorScheme={
                                      mpt.dateOfCompletion ? "green" : "yellow"
                                    }
                                  >
                                    {mpt.dateOfCompletion
                                      ? "Completed"
                                      : "In progress"}
                                  </Tag>
                                </HStack>
                              </SimpleGrid>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel
                              pb={4}
                              px={4}
                              my={4}
                              border="2px solid"
                              borderRadius="10px"
                            >
                              <VStack spacing={5} w="100%">
                                {mpt.activities.length > 0 && (
                                  <Table variant="striped" colorScheme="teal">
                                    <TableCaption
                                      placement="top"
                                      pt={0}
                                      fontWeight="bold"
                                    >
                                      Learning content completed
                                    </TableCaption>
                                    <Thead>
                                      <Tr>
                                        <Th>Name</Th>
                                        <Th>Date of completion</Th>
                                        <Th>Feedback</Th>
                                        <Th>Completed content</Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {progressTrackers
                                        .filter((pt) =>
                                          mpt.progressTrackers
                                            .map(
                                              (mptpt) => mptpt.progressTrackerID
                                            )
                                            .includes(pt.progressTrackerID)
                                        )
                                        .map((pt) => (
                                          <Tr
                                            key={pt.progressTrackerID}
                                            fontWeight="normal"
                                          >
                                            <Td>
                                              <Link
                                                as={NavLink}
                                                to={{
                                                  pathname: `/admin/tables`,
                                                  search: `/?id=${pt.activityID}`,
                                                }}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {pt.activityName}
                                              </Link>
                                            </Td>
                                            <Td>
                                              {moment(
                                                pt.dateOfCompletion
                                              ).format("d MMM YY")}
                                            </Td>
                                            <Td w="100%">
                                              {pt.feedback || "-"}
                                            </Td>
                                            <Td>
                                              {pt.completedContent ? (
                                                <Link
                                                  href={`${baseURL}/api/s3/uploads/${pt.completedContent}`}
                                                  download
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  color="gray.500"
                                                >
                                                  View Uploaded File
                                                </Link>
                                              ) : (
                                                "-"
                                              )}
                                            </Td>
                                          </Tr>
                                        ))}
                                    </Tbody>
                                  </Table>
                                )}
                                <Box w="100%">
                                  <Heading size="sm">Feedback</Heading>
                                  <Editable
                                    textAlign="center"
                                    placeholder={
                                      mpt.expertFeedback || "Give feedback"
                                    }
                                    defaultValue={
                                      mpt.expertFeedback || undefined
                                    }
                                    border="2px solid"
                                    borderColor="green.300"
                                    borderRadius="10px"
                                    p="10px"
                                    my="20px"
                                    w="100%"
                                    onSubmit={(val) => submitFeedback(mpt, val)}
                                    isPreviewFocusable={false}
                                  >
                                    <HStack>
                                      <EditablePreview
                                        w="100%"
                                        fontWeight="normal"
                                      />
                                      <Input
                                        as={EditableTextarea}
                                        fontWeight="normal"
                                      />
                                      <EditableControls />
                                    </HStack>
                                  </Editable>
                                  <LikeDislikeButtonGroup
                                    label="Response:"
                                    value={
                                      typeof mpt.isFeedbackLiked ===
                                        "undefined" ||
                                      mpt.isFeedbackLiked === null
                                        ? "none"
                                        : mpt.isFeedbackLiked
                                        ? "like"
                                        : "dislike"
                                    }
                                    direction="row"
                                    mx="auto"
                                  />
                                </Box>
                              </VStack>
                            </AccordionPanel>
                          </AccordionItem>
                        ))}
                    </Accordion>
                  </>
                ))}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
