import { CUIAutoComplete } from "chakra-ui-autocomplete";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import * as yup from "yup";

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { baseURL } from "../../../../common/axios";
import LearningContentDownloader from "./LearningContentDownloader";
import { isValidHttpUrl, objectMap } from "../../../../common/utils";
import {
  IContentType,
  IAgeGroup,
  IDevelopmentArea,
  ISteamSubject,
  IActivityType,
  DevelopmentArea,
  DevelopmentAreaItems,
  uploadFile,
  ContentType,
  ActivityType,
  AgeGroup,
  SteamSubject,
  Activity,
  ActivityObjective,
} from "../constants";
import { useImageUploader, SimpleImageUploader } from "components/ImageUpload";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addActivity, QUERY_KEYS, updateActivity } from "common/api";
import MultipleCategoryFormField from "components/MultipleCategoryFormField";
import ActivityObjectivesFormField from "./ActivityObjectivesFormField";

interface Props {
  disclosureProps: UseDisclosureReturn;
  activity?: Activity;
  editMode: boolean;
}

export interface IEditContentFormInputs {
  name: string;
  description?: string;
  contentType: IContentType;
  hardwareRequired: boolean;
  ageGroups: IAgeGroup[];
  developmentAreas: IDevelopmentArea[];
  steamSubjects: ISteamSubject[];
  activityTypes: IActivityType[];
  resources?: FileList;
  media: string;
  tags: Record<string, string[]>;
  objectives: ActivityObjective[];
}

const schema = yup
  .object({
    name: yup
      .string()
      .required("Please provide a name for this learning content")
      .trim(),
    description: yup.string().trim(),
    contentType: yup
      .string()
      .required("Please specify the content type")
      .trim(),
    hardwareRequired: yup.boolean().default(false),
    ageGroups: yup.array(yup.string()).default([]),
    developmentAreas: yup.array(yup.string()).default([]),
    steamSubjects: yup.array(yup.string()).default([]),
    activityTypes: yup.array(yup.string()).default([]),
    resources: yup.mixed(),
    // .test('hasFile', 'Please upload a file', value =>
    //   Boolean(value && value instanceof FileList && value.length > 0),
    // ),
    media: yup.string().trim(),
    tags: yup.object(),
    objectives: yup.array(
      yup.object({
        name: yup.string().required(),
        description: yup.string(),
      })
    ),
  })
  .required();

export interface Item {
  label: string;
  value: string;
}

const defaultTags = (Object.fromEntries(
  Object.values(DevelopmentArea).map((area) => [area, []])
) as unknown) as Record<IDevelopmentArea, Item[]>;

const EditContentModal: React.FC<Props> = ({
  disclosureProps,
  activity: activityToEdit,
  editMode,
}) => {
  const [createAnother, setCreateAnother] = useState(false);
  const [
    selectedDevelopmentAreaTags,
    setSelectedDevelopmentAreaTags,
  ] = React.useState<Record<IDevelopmentArea, Item[]>>(defaultTags);
  const toast = useToast();
  const { images, setImages, uploadImage } = useImageUploader();

  const handleSelectedItemsChange = useCallback(
    (developmentArea: IDevelopmentArea, selectedItems?: Item[]) => {
      if (selectedItems) {
        setSelectedDevelopmentAreaTags((currentSelected) => {
          return { ...currentSelected, [developmentArea]: selectedItems };
        });
      }
    },
    []
  );

  const methods = useForm<IEditContentFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      steamSubjects: [],
      activityTypes: [],
      developmentAreas: [],
      ageGroups: [],
      hardwareRequired: false,
      objectives: [],
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
    trigger,
  } = methods;

  useEffect(() => {
    if (!activityToEdit) {
      reset();
      return;
    }

    setValue("name", activityToEdit.name);
    setValue("hardwareRequired", activityToEdit.hardwareRequired ?? false);
    // @ts-ignore
    setValue("steamSubjects", activityToEdit.steamSubjects);
    // @ts-ignore
    setValue("activityTypes", activityToEdit.activityTypes);
    // @ts-ignore
    setValue("developmentAreas", activityToEdit.developmentAreas);
    // @ts-ignore
    setValue("ageGroups", activityToEdit.ageGroups);
    // @ts-ignore
    setValue("contentType", activityToEdit.contentType);
    setValue("description", activityToEdit.description);
    setValue("objectives", activityToEdit.objectives);
    // @ts-ignore
    setSelectedDevelopmentAreaTags(
      // @ts-ignore
      {
        ...defaultTags,
        ...objectMap<string[], Item[]>(activityToEdit.tags, (tagArr) =>
          tagArr.map((tag) => {
            return {
              value: tag,
              label: tag,
            };
          })
        ),
      }
    );
  }, [activityToEdit, reset, setValue]);

  const queryClient = useQueryClient();
  const addActivityMutation = useMutation(addActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.activities]);
    },
  });
  const updateActivityMutation = useMutation(updateActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.activities]);
    },
  });

  const watchContentType = watch("contentType");
  const watchActivityTypes = watch("activityTypes");
  const watchAgeGroups = watch("ageGroups");
  const watchDevelopmentAreas = watch("developmentAreas");
  const watchSteamSubjects = watch("steamSubjects");
  const watchHardwareRequired = watch("hardwareRequired");
  const watchResources = watch("resources");
  const watchObjectives = watch("objectives");

  const hasUploadedFile = watchResources && watchResources.length > 0;

  const createActivity = useCallback(
    async (
      data: Omit<Activity, "objectives"> & {
        objectives: Array<Omit<ActivityObjective, "activityObjectiveID">>;
      }
    ) => {
      const response = await addActivityMutation.mutateAsync(data);

      if (response.status === 201) {
        toast({
          title: "Success",
          description: `You've successfully added ${data.name}`,
          status: "success",
          duration: 4000,
          isClosable: true,
        });

        reset();
        setSelectedDevelopmentAreaTags(defaultTags);

        if (!createAnother) {
          disclosureProps.onClose();
        }
      } else {
        toast({
          title: "Error",
          description: `Something unexpected happened\n${response.data.errors}`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    },
    [addActivityMutation, createAnother, disclosureProps, reset, toast]
  );

  const editActivity = useCallback(
    async (
      data: Activity & {
        newName: string;
      }
    ) => {
      const response = await updateActivityMutation.mutateAsync(data);

      if (response.status === 204) {
        toast({
          title: "Success",
          description: `You've successfully edited ${data.name}`,
          status: "success",
          duration: 4000,
          isClosable: true,
        });

        reset();
        setSelectedDevelopmentAreaTags(defaultTags);

        disclosureProps.onClose();
      } else {
        toast({
          title: "Error",
          description: `Something unexpected happened\n${response.data.errors}`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    },
    [disclosureProps, reset, toast, updateActivityMutation]
  );

  const onSubmit = async (data: IEditContentFormInputs) => {
    const { resources, ...restData } = data;

    const resourceFileName =
      resources && resources.length > 0
        ? await uploadFile(resources[0], "learning-content/")
        : "";
    const mediaFileName =
      images && images.length > 0 && images[0].file
        ? await uploadFile(images[0].file, "images/")
        : "";

    const resourceName = resourceFileName.replace("learning-content/", "");

    if (activityToEdit && editMode) {
      const editedActivity: Activity & {
        newName: string;
        newObjectives: Array<Omit<ActivityObjective, "activityObjectiveID">>;
      } = {
        ...restData,
        name: activityToEdit.name,
        // TODO: merge SkillEnum and IDevelopmentArea
        // @ts-ignore
        tags: objectMap(selectedDevelopmentAreaTags, (items) =>
          items.map((item) => item.value)
        ),
        id: activityToEdit.id,
        objectives: restData.objectives.filter((o) => !!o.activityObjectiveID),
      };

      if (data.name !== activityToEdit.name) {
        editedActivity.newName = data.name;
      }

      if (resourceName) {
        editedActivity.resources = resourceName;
      }

      if (mediaFileName) {
        editedActivity.media = mediaFileName;
      }

      const newObjectives = restData.objectives.filter(
        (o) => o.activityObjectiveID === ""
      );

      if (newObjectives) {
        editedActivity.newObjectives = newObjectives.map((no) => ({
          name: no.name,
          description: no.description,
        }));
      }

      await editActivity(editedActivity);
    } else {
      await createActivity({
        ...restData,
        resources: resourceName,
        media: mediaFileName,
        // TODO: merge SkillEnum and IDevelopmentArea
        // @ts-ignore
        tags: objectMap(selectedDevelopmentAreaTags, (items) =>
          items.map((item) => item.value)
        ),
        objectives: restData.objectives.map((o) => ({
          name: o.name,
          description: o.description,
        })),
      });
    }
  };

  return (
    <Modal
      isOpen={disclosureProps.isOpen}
      onClose={disclosureProps.onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent textAlign="center" py="20px" fontWeight="bold">
        <ModalHeader>
          {editMode && activityToEdit
            ? `Edit ${activityToEdit.name}`
            : "Add an activity"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <form>
              <FormControl
                my="15px"
                display="flex"
                justifyContent="center"
                flexDir="column"
                alignItems="center"
              >
                <SimpleImageUploader
                  images={images}
                  setImages={setImages}
                  uploadImage={uploadImage}
                  dashedBorder
                  placeholderImageUrl={
                    activityToEdit && activityToEdit.media
                      ? isValidHttpUrl(activityToEdit.media)
                        ? activityToEdit.media
                        : `${baseURL}/api/s3/uploads/${activityToEdit.media}`
                      : undefined
                  }
                  restrictImageSize={false}
                />
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.name} my="40px">
                <FormLabel htmlFor="name">Activity name</FormLabel>
                <Input id="name" placeholder="Name" {...register("name")} />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.description} my="40px">
                <FormLabel htmlFor="description">Description</FormLabel>
                <Textarea
                  id="description"
                  placeholder="Description"
                  {...register("description")}
                />
              </FormControl>

              <FormControl
                isRequired
                isInvalid={!!errors.resources}
                border="none"
                my="40px"
              >
                <HStack alignItems="center">
                  <FormLabel mr="20px" mb={0}>
                    Downloadable
                  </FormLabel>
                  {editMode &&
                    activityToEdit &&
                    activityToEdit.resources &&
                    activityToEdit.contentType !== "Game" &&
                    !hasUploadedFile && (
                      <LearningContentDownloader
                        fileName={activityToEdit.resources}
                        label="View current"
                        buttonProps={{
                          borderRadius: "10px",
                          mb: 0,
                          w: "fit-content",
                        }}
                      />
                    )}
                  <FormLabel
                    htmlFor="resources"
                    p="10px"
                    h="100%"
                    mb={0}
                    borderRadius="10px"
                    bgColor="secondary.green"
                    cursor="pointer"
                    sx={{
                      ">span": { display: "none" },
                      "&:hover": { bgColor: "gray.100" },
                    }}
                  >
                    {hasUploadedFile
                      ? "Change"
                      : editMode
                      ? "Upload New"
                      : "Upload"}
                  </FormLabel>
                  {hasUploadedFile && watchResources && (
                    <>
                      <Text
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        {watchResources[0].name}
                      </Text>
                      <Icon
                        as={AiOutlineCloseCircle}
                        color="red"
                        onClick={() => setValue("resources", undefined)}
                        cursor="pointer"
                      />
                    </>
                  )}
                  <Input
                    id="resources"
                    type="file"
                    accept=".pdf"
                    // to prevent native validation
                    required={false}
                    {...register("resources")}
                    opacity={0}
                    w={0}
                    h={0}
                  />
                </HStack>
                <FormErrorMessage>
                  {errors.resources && errors.resources.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors.hardwareRequired}
                minW="40%"
                display="flex"
                flexDir="row"
                my="40px"
              >
                <FormLabel mr="30px" mb="0">
                  Is the box required?
                </FormLabel>
                <Checkbox
                  id="hardwareRequired"
                  size="lg"
                  colorScheme="green"
                  isChecked={watchHardwareRequired}
                  onChange={(event) =>
                    setValue("hardwareRequired", event.target.checked)
                  }
                />
              </FormControl>

              <FormControl
                isRequired
                isInvalid={!!errors.contentType}
                mt="30px"
              >
                <FormLabel>Content Type</FormLabel>
                <HStack w="100%">
                  {Object.values(ContentType).map((contentType) => (
                    <Button
                      key={contentType}
                      width="100%"
                      fontSize="13px"
                      borderWidth={
                        watchContentType === contentType ? "3px" : ""
                      }
                      borderColor="blue.300"
                      onClick={() => {
                        setValue("contentType", contentType);
                        void trigger("contentType");
                      }}
                      {...register("contentType")}
                    >
                      {contentType}
                    </Button>
                  ))}
                </HStack>
                <FormErrorMessage>
                  {errors.contentType && errors.contentType.message}
                </FormErrorMessage>
                <Input
                  id="contentType"
                  // to prevent native validation
                  required={false}
                  {...register("contentType")}
                  opacity={0}
                  maxW={0}
                  maxH={0}
                />
              </FormControl>

              <MultipleCategoryFormField
                //@ts-ignore
                error={errors.activityTypes}
                label="Activity Types"
                options={ActivityType}
                watchValue={watchActivityTypes}
                setValue={setValue}
                register={register}
                fieldKey="activityTypes"
              />

              <MultipleCategoryFormField
                //@ts-ignore
                error={errors.ageGroups}
                label="Age Groups"
                options={AgeGroup}
                watchValue={watchAgeGroups}
                setValue={setValue}
                register={register}
                fieldKey="ageGroups"
                buttonColor="primary.red"
              />

              <MultipleCategoryFormField
                //@ts-ignore
                error={errors.steamSubjects}
                label="STEAM Subjects"
                options={SteamSubject}
                watchValue={watchSteamSubjects}
                setValue={setValue}
                register={register}
                fieldKey="steamSubjects"
              />

              <MultipleCategoryFormField
                //@ts-ignore
                error={errors.developmentAreas}
                label="Developmental Areas"
                options={DevelopmentArea}
                watchValue={watchDevelopmentAreas}
                setValue={setValue}
                fieldKey="developmentAreas"
                buttonColor="primary.red"
                register={register}
              />

              {watchDevelopmentAreas.map((selectedDevelopmentArea) => (
                <CUIAutoComplete
                  key={selectedDevelopmentArea}
                  label={`${selectedDevelopmentArea} Tags`}
                  placeholder={`Select all ${selectedDevelopmentArea.toLowerCase()} tags that applies to this content`}
                  disableCreateItem
                  items={DevelopmentAreaItems[selectedDevelopmentArea].map(
                    (item) => {
                      return {
                        value: item,
                        label: item,
                      };
                    }
                  )}
                  selectedItems={
                    selectedDevelopmentAreaTags[selectedDevelopmentArea]
                  }
                  onSelectedItemsChange={(changes) =>
                    handleSelectedItemsChange(
                      selectedDevelopmentArea,
                      changes.selectedItems
                    )
                  }
                  labelStyleProps={{
                    mb: 0,
                  }}
                  tagStyleProps={{
                    bgColor: "lightgreen",
                    h: "30px",
                    mb: "10px",
                    ml: 2,
                  }}
                  inputStyleProps={{
                    mt:
                      selectedDevelopmentAreaTags[selectedDevelopmentArea]
                        .length > 0
                        ? "5px"
                        : 0,
                    mb: "20px",
                  }}
                  toggleButtonStyleProps={{
                    sx: {
                      // as toggle button in downshift package has a higher specificity css rule, &&& is used for higher specificity
                      "&&&": {
                        mt:
                          selectedDevelopmentAreaTags[selectedDevelopmentArea]
                            .length > 0
                            ? "5px"
                            : 0,
                      },
                    },
                  }}
                />
              ))}

              <ActivityObjectivesFormField objectives={watchObjectives} />

              <Flex mt="50px" mb="15px" justifyContent="flex-end">
                {/* <Button
                colorScheme="red"
                borderRadius="23px"
                p="14px 32px"
                maxW="170px"
                boxShadow="lg"
                onClick={() => reset()}
              >
                Clear
              </Button> */}
                {!editMode && (
                  <FormControl
                    display="flex"
                    flexDir="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <FormLabel mr="30px" mb="0">
                      Create another?
                    </FormLabel>
                    <Checkbox
                      id="createAnother"
                      size="lg"
                      colorScheme="green"
                      isChecked={createAnother}
                      onChange={(event) =>
                        setCreateAnother(event.target.checked)
                      }
                    />
                  </FormControl>
                )}

                <Button
                  borderRadius="23px"
                  p="14px 32px"
                  maxW="170px"
                  ml="30px"
                  boxShadow="lg"
                  onClick={handleSubmit(onSubmit, (error) =>
                    console.error(error)
                  )}
                  isLoading={isSubmitting}
                  colorScheme="blue"
                >
                  {editMode ? "Edit" : "Create"}
                </Button>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditContentModal;
