import React from "react";

import { Image, Text, VStack } from "@chakra-ui/react";

import BaseLoginModal from "./BaseLoginModal";
import LoginForm from "./LoginForm";
import { GoogleButton } from "./SocialButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const LoginModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <BaseLoginModal
      isOpen={isOpen}
      closeModal={onClose}
      heading="Welcome Back!"
      subHeading="Login to your account"
      descriptionChildren={
        <Image
          src="/login-registration-modal/login-img.png"
          alt="Kid doing thumbs up"
          borderRadius="142px"
        />
      }
      formChildren={
        <>
          <VStack spacing="20px" mt="20px">
            <GoogleButton closeModal={onClose} />
          </VStack>
          <Text fontSize="18px" fontWeight="bold" textAlign="center" my="20px">
            OR
          </Text>
          <LoginForm closeModal={onClose} />
        </>
      }
    />
  );
};

export default LoginModal;
