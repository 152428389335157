import {
  Button,
  HStack,
  Icon,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getActivities, QUERY_KEYS } from "common/api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import TablesTableRow from "components/Tables/TablesTableRow";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { Activity, IAgeGroup, reverseAgeGroupMatch } from "../constants";
import EditContentModal from "./EditContentModal";

const contentTypeSortFunc = (a: Activity, b: Activity) =>
  a.contentType.localeCompare(b.contentType);

const ageGroupSortFunc = (
  a: Activity,
  b: Activity,
  ageGroupSortOrder: boolean
) => {
  const first = (ageGroupSortOrder ? b : a).ageGroups.map(
    (a) => reverseAgeGroupMatch[a as IAgeGroup]
  );
  const second = (ageGroupSortOrder ? a : b).ageGroups.map(
    (a) => reverseAgeGroupMatch[a as IAgeGroup]
  );
  const func = ageGroupSortOrder ? Math.max : Math.min;
  return func(...first) - func(...second);
};

const LearningContentTable: React.FC = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const disclosureProps = useDisclosure();

  const activityQuery = useQuery([QUERY_KEYS.activities], getActivities);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [activityToEdit, setActivityToEdit] = useState<Activity>();
  const [contentTypeSortOrder, setContentTypeSortOrder] = useState(false);
  const [ageGroupSortOrder, setAgeGroupSortOrder] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const displayedActivities = useMemo(
    () =>
      searchKeyword === ""
        ? activities
        : activities.filter((a) =>
            a.name.toLowerCase().includes(searchKeyword.trim().toLowerCase())
          ),
    [activities, searchKeyword]
  );

  useEffect(() => {
    if (activityQuery.data) {
      setActivities(activityQuery.data);
    }
  }, [activityQuery.data]);

  useEffect(() => {
    setActivities((activities) =>
      activities
        .sort((a, b) => ageGroupSortFunc(a, b, ageGroupSortOrder))
        .map((activity) => {
          const sortedAgeGroups = activity.ageGroups.sort();
          return {
            ...activity,
            ageGroups: ageGroupSortOrder
              ? sortedAgeGroups.reverse()
              : sortedAgeGroups,
          };
        })
    );
  }, [ageGroupSortOrder]);

  const toggleContentTypeSortOrder = useCallback(() => {
    setContentTypeSortOrder((c) => !c);
    setActivities((activities) =>
      activities.sort((a, b) =>
        contentTypeSortFunc(
          contentTypeSortOrder ? a : b,
          contentTypeSortOrder ? b : a
        )
      )
    );
  }, [contentTypeSortOrder]);

  const toggleAgeGroupSortOrder = useCallback(() => {
    setAgeGroupSortOrder((c) => !c);
  }, []);

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <EditContentModal
        disclosureProps={disclosureProps}
        editMode={!!activityToEdit}
        activity={activityToEdit}
      />
      <CardHeader p="6px 0px 22px 0px">
        <HStack w="100%" pr={2}>
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Learning Content
          </Text>
          <Spacer />
          <Button
            onClick={() => {
              disclosureProps.onOpen();
              setActivityToEdit(undefined);
            }}
          >
            Create
          </Button>
        </HStack>
      </CardHeader>
      <VStack alignItems="flex-start" spacing={2} mb="20px">
        <SearchBar setSearchKeyword={setSearchKeyword} maxW="600px" />
        {searchKeyword && (
          <Text fontSize="xs" color="gray.400">
            Searching for {searchKeyword}...
          </Text>
        )}
      </VStack>
      <CardBody>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {[
                "Name",
                "Description",
                "Content Type",
                "Development Areas",
                "Age Groups",
                "",
              ].map((caption, idx) => {
                return (
                  <Th
                    color="gray.400"
                    key={idx}
                    ps={idx === 0 ? "0px" : undefined}
                  >
                    <HStack justifyContent="center" textAlign="center">
                      <Text>{caption}</Text>
                      {(caption === "Content Type" ||
                        caption === "Age Groups") && (
                        <Icon
                          as={FaChevronDown}
                          onClick={
                            caption === "Content Type"
                              ? toggleContentTypeSortOrder
                              : toggleAgeGroupSortOrder
                          }
                          transition="all 0.15s ease-in"
                          transform={
                            caption === "Content Type"
                              ? contentTypeSortOrder
                                ? "rotate(180deg)"
                                : "none"
                              : ageGroupSortOrder
                              ? "none"
                              : "rotate(180deg)"
                          }
                          cursor="pointer"
                        />
                      )}
                    </HStack>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {displayedActivities.map((row) => {
              return (
                <TablesTableRow
                  key={row.id}
                  id={row.id}
                  name={row.name}
                  description={row.description}
                  domain={""}
                  contentType={row.contentType}
                  ageGroups={row.ageGroups}
                  developmentAreas={row.developmentAreas}
                  date={row.createdAt}
                  media={row.media}
                  onClick={() => {
                    disclosureProps.onOpen();
                    setActivityToEdit(row);
                  }}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default LearningContentTable;
