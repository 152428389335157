import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Heading,
  HStack,
  Portal,
  Select,
  VStack,
} from "@chakra-ui/react";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar";
import { UserContext } from "contexts/UserContext";
import React, { memo, useCallback, useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes, { Routes } from "routes";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";

export default function Dashboard() {
  const {
    isLoggedIn,
    onOpen,
    curriculums,
    selectedCurriculum,
    setSelectedCurriculum,
  } = useContext(UserContext);

  const getActiveRoute: (routes: Routes[]) => string = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      if (route.collapse && route.views) {
        let collapseActiveRoute = getActiveRoute(route.views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (route.category && route.views) {
        let categoryActiveRoute = getActiveRoute(route.views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          route.layout &&
          route.path &&
          window.location.href.indexOf(route.layout + route.path) !== -1
        ) {
          return route.name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar: (routes: Routes[]) => boolean = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      if (route.category && route.views) {
        let categoryActiveNavbar = getActiveNavbar(route.views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          route.layout &&
          route.path &&
          window.location.href.indexOf(route.layout + route.path) !== -1
        ) {
          if (route.secondaryNavbar) {
            return route.secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = useCallback((routes: Routes[]) => {
    return routes.map((prop, key): JSX.Element | null => {
      if (prop.collapse) {
        // @ts-ignore
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }, []);

  const RenderedRoutes = memo(() => <>{getRoutes(routes)}</>);

  useEffect(() => {
    if (document) {
      document.documentElement.dir = "ltr";
    }
  }, []);

  return (
    <>
      <Sidebar
        routes={routes}
        logoText={"ADMIN DASHBOARD"}
        display="none"
        sidebarVariant="transparent"
      />
      <MainPanel
        w={{
          base: "100%",
          xl: "calc(100% - 275px)",
        }}
      >
        <Portal>
          <AdminNavbar
            // @ts-ignore
            logoText={"ADMIN DASHBOARD"}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            fixed={false}
          />
        </Portal>

        <PanelContent>
          <PanelContainer>
            <Switch>
              {isLoggedIn ? (
                <VStack spacing={4} pt={{ base: "120px", md: "75px" }}>
                  <HStack spacing={4} p={4} bgColor="white" borderRadius="10px">
                    <Heading fontSize="xl" color="black">
                      Curriculum
                    </Heading>
                    <Select
                      placeholder="Select curriculum"
                      maxW="300px"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setSelectedCurriculum(
                          curriculums?.find(
                            (c) => c.curriculumID === e.target.value
                          )
                        );
                      }}
                      value={selectedCurriculum?.curriculumID}
                    >
                      {curriculums?.map((c) => (
                        <option key={c.curriculumID} value={c.curriculumID}>
                          {c.name}
                        </option>
                      ))}
                    </Select>
                  </HStack>
                  <RenderedRoutes />
                </VStack>
              ) : (
                <Center
                  flexDirection="column"
                  pt={{ base: "120px", md: "75px" }}
                >
                  <Alert status="error" w="auto">
                    <AlertIcon />
                    <AlertTitle>You are not logged in!</AlertTitle>
                    <AlertDescription>
                      Login{" "}
                      <Box
                        as="span"
                        onClick={onOpen}
                        fontWeight="bold"
                        cursor="pointer"
                        color="darkblue"
                      >
                        here
                      </Box>
                    </AlertDescription>
                  </Alert>
                </Center>
              )}
              <Redirect from="/admin" to="/admin/tables" />
            </Switch>
          </PanelContainer>
        </PanelContent>
      </MainPanel>
    </>
  );
}
