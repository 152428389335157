import React, { ChangeEvent, useCallback, useState } from "react";

import {
  Box,
  Center,
  CircularProgress,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import { User, UserRole } from "types/users";
import { EmailIcon, Search2Icon } from "@chakra-ui/icons";
import { makeFullUrlApiCall } from "common/axios";
import AdminManagementTableRow from "./AdminManagementTableRow";
import { AiOutlinePlus } from "react-icons/ai";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { updateUser, QUERY_KEYS } from "common/api";

interface Props {
  disclosureProps: UseDisclosureReturn;
}

const AdminCandidateTable: React.FC<{ users: User[] }> = ({ users }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const updateUserMutation = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.admins]);
    },
  });
  const [isAdding, setIsAdding] = useState(false);

  return (
    <Table variant="simple" size="sm" color="gray.700">
      <Thead>
        <Tr my=".8rem" pl="0px" color="gray.400">
          {["Name", "Email", "Login Type", "Role", ""].map((caption, idx) => {
            return (
              <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : undefined}>
                <HStack justifyContent="center" textAlign="center">
                  <Text>{caption}</Text>
                </HStack>
              </Th>
            );
          })}
        </Tr>
      </Thead>

      <Tbody>
        {users.map((row) => {
          return (
            <AdminManagementTableRow
              key={row.userId}
              admin={row}
              actionButton={
                <IconButton
                  colorScheme="blue"
                  onClick={async () => {
                    setIsAdding(true);
                    const response = await updateUserMutation.mutateAsync({
                      userId: row.userId,
                      role: UserRole.admin,
                    });
                    setIsAdding(false);

                    if (response.status !== 204) {
                      toast({
                        title: "Error",
                        description: "Something unexpected occurred",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    } else {
                      toast({
                        title: "Success",
                        description: `${row.email} successfully added as admin`,
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  }}
                  icon={<Icon as={AiOutlinePlus} />}
                  aria-label="add admin button"
                  size="xs"
                  isLoading={isAdding}
                />
              }
              showProfilePic={false}
            />
          );
        })}
      </Tbody>
    </Table>
  );
};

const AdminManagementModal: React.FC<Props> = ({ disclosureProps }) => {
  const toast = useToast();
  const [foundUsers, setFoundUsers] = useState<User[] | null>(null);
  const [value, setValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const findUser = useCallback(
    async (email: string) => {
      if (
        // eslint-disable-next-line no-useless-escape
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        toast({
          title: "Error",
          description: `${email} is not a valid email`,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      setIsSearching(true);
      const response = await makeFullUrlApiCall(`user?email=${email}`);
      setIsSearching(false);
      if (response.status !== 200) {
        return;
      }

      setFoundUsers(
        (response.data.data as User[]).filter((u) => u.role !== UserRole.admin)
      );
    },
    [toast]
  );

  return (
    <Modal
      isOpen={disclosureProps.isOpen}
      onClose={disclosureProps.onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent textAlign="center" py="20px">
        <ModalHeader>Add admin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
            >
              <EmailIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Enter email"
              value={value}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setValue(event.target.value)
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  findUser(value);
                }
              }}
            />
            <InputRightElement>
              {isSearching ? (
                <CircularProgress isIndeterminate size="20px" />
              ) : (
                <Search2Icon />
              )}
            </InputRightElement>
          </InputGroup>
          <Box my="20px">
            {foundUsers !== null &&
              (foundUsers.length === 0 ? (
                <Center w="100%" color="red.300">
                  No users found
                </Center>
              ) : (
                <AdminCandidateTable users={foundUsers} />
              ))}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AdminManagementModal;
