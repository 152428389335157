import React from "react";

import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  descriptionChildren: React.ReactNode;
  formChildren: React.ReactNode;
  heading: string;
  subHeading: string;
}

const BaseLoginModal: React.FC<Props> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.closeModal}
      size="4xl"
      scrollBehavior="outside"
      motionPreset="slideInBottom"
      isCentered
    >
      <ModalOverlay />
      <ModalContent m="15px">
        <ModalCloseButton />
        <ModalBody p={0}>
          <SimpleGrid columns={{ base: 1, md: 2 }}>
            <Flex
              position="relative"
              bgImage="/login-registration-modal/background.png"
              direction="column"
              p="40px"
              justifyContent="center"
            >
              {props.descriptionChildren}
            </Flex>
            <Flex direction="column" p="40px">
              <Heading fontWeight="bold" fontSize="23px" mt="20px">
                {props.heading}
              </Heading>
              <Text>{props.subHeading}</Text>

              {props.formChildren}
            </Flex>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BaseLoginModal;
