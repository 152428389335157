import moment from "moment";
import { Curriculum } from "views/Dashboard/Milestones/interfaces";

export function isValidHttpUrl(test: string): boolean {
  let url;

  try {
    url = new URL(test);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

/** Returns a new object with the values at each key mapped using mapFn(value) */
export function objectMap<V, U>(
  object: Record<string, V>,
  mapFn: (value: V) => U
): Record<string, U> {
  return Object.keys(object).reduce((result, key) => {
    result[key] = mapFn(object[key]);
    return result;
  }, {} as Record<string, U>);
}

export function getStandardCurriculum(curriculums: Curriculum[]) {
  const standardCurriculum = curriculums.find(
    (c) => c.name === "Standard Curriculum"
  );
  if (!standardCurriculum) {
    console.error("Could not find standard curriculum");
  }

  return standardCurriculum;
}

export function getAge(dateString: Date) {
  const birthdayMoment = moment(dateString);
  const currMoment = moment();
  return currMoment.diff(birthdayMoment, "years");
}

export function getAgeMonths(date: Date) {
  const birthdayMoment = moment(date);
  const currMoment = moment();
  return currMoment.diff(birthdayMoment, "months");
}
