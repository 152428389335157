import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Icon,
  IconButton,
  VStack,
  HStack,
  Editable,
  EditableInput,
  EditablePreview,
  FormLabel,
  Box,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  AiOutlineClose,
  AiOutlineDotChart,
  AiOutlinePlus,
} from "react-icons/ai";
import { ActivityObjective } from "../constants";
import { IEditContentFormInputs } from "./EditContentModal";

interface Props {
  objectives: ActivityObjective[];
}

const ActivityObjectivesFormField: React.FC<Props> = ({ objectives }) => {
  const { setValue } = useFormContext<IEditContentFormInputs>();
  const [newObj, setObj] = useState("");

  return (
    <Box>
      <FormLabel mr="20px">Learning Objectives</FormLabel>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="gray.300"
          fontSize="1.2em"
        >
          <Icon as={AiOutlineDotChart} color="gray.300" />
        </InputLeftElement>
        <Input
          placeholder="Add new objectives"
          value={newObj}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setObj(event.target.value)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setValue("objectives", [
                ...objectives,
                {
                  activityObjectiveID: "",
                  name: newObj,
                  description: "",
                },
              ]);
              setObj("");
            }
          }}
        />
        <InputRightElement>
          <Icon as={AiOutlinePlus} />
        </InputRightElement>
      </InputGroup>
      <VStack>
        {objectives.map((o) => {
          return (
            <HStack
              key={o.name}
              pr="4%"
              pl="2%"
              mt="20px"
              justify="space-between"
              w="100%"
            >
              <Editable
                maxW="90%"
                minW="90%"
                textAlign="start"
                defaultValue={o.name}
                border="2px solid"
                borderColor="blue.300"
                borderRadius="10px"
                onSubmit={(val) => {
                  setValue("objectives", [
                    ...objectives.filter(
                      (currentObj) => currentObj.name !== o.name
                    ),
                    {
                      ...o,
                      name: val,
                    },
                  ]);
                }}
              >
                <EditablePreview w="100%" px="10px" />
                <EditableInput w="100%" px="10px" />
              </Editable>

              <IconButton
                colorScheme="red"
                onClick={() => {
                  setValue(
                    "objectives",
                    objectives.filter(
                      (currentObj) => currentObj.name !== o.name
                    )
                  );
                }}
                icon={<Icon as={AiOutlineClose} />}
                aria-label="remove objective button"
                size="xs"
              />
            </HStack>
          );
        })}
      </VStack>
    </Box>
  );
};

export default ActivityObjectivesFormField;
