import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ProfileIcon } from "components/Icons/Icons";
import { ItemContent } from "components/Menu/ItemContent";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import { UserContext } from "contexts/UserContext";
import React, { useContext } from "react";
import { BsBoxArrowInRight } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import routes from "routes";

export default function HeaderLinks(props: {
  variant?: string;
  fixed: boolean;
  secondary: boolean;
  logoText: string;
  openLoginModal: () => void;
}) {
  const { isLoggedIn, user, deleteUser } = useContext(UserContext);
  const { secondary, openLoginModal, ...rest } = props;

  // let mainTeal = useColorModeValue("teal.300", "teal.300");
  // let inputBg = useColorModeValue("white", "gray.800");
  // let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  // let searchIcon = useColorModeValue("gray.700", "gray.200");

  if (secondary) {
    navbarIcon = "white";
    // mainText = "white";
  }
  // const settingsRef = React.useRef();
  return (
    <HStack
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      spacing={5}
    >
      {isLoggedIn ? (
        <Menu>
          <MenuButton>
            <HStack>
              <Icon as={FaUserCircle} color={navbarIcon} boxSize="24px" />
              <Text>{user?.email}</Text>
            </HStack>
          </MenuButton>
          <MenuList p="8px">
            <MenuItem borderRadius="8px" onClick={deleteUser}>
              <ItemContent icon={BsBoxArrowInRight} boldInfo="Sign out" />
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <Button
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant="transparent-with-icon"
          leftIcon={
            <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
          }
          onClick={openLoginModal}
        >
          <Text display={{ sm: "none", md: "flex" }}>Login</Text>
        </Button>
      )}
      <SidebarResponsive
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
    </HStack>
  );
}
