/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

// import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin";
import UserProvider from "contexts/UserContext";
import theme from "theme/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ChakraProvider } from "@chakra-ui/react";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <BrowserRouter>
          <Switch>
            <React.Fragment>
              {/* <Route path={`/auth`} component={AuthLayout} /> */}
              <Route path={`/admin`} component={AdminLayout} />
              <Redirect from={`/`} to="/admin/tables" />
            </React.Fragment>
          </Switch>
        </BrowserRouter>
      </UserProvider>
    </QueryClientProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
