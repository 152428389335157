import {
  Badge,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Spacer,
  Td,
  Text,
  Tr,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { BiEdit, BiGridVertical } from "react-icons/bi";
import { useSortable } from "@dnd-kit/sortable";
import React, { useContext, useMemo } from "react";
import { LearningMilestone } from "../interfaces";
import { QUERY_KEYS, getActivities, deleteMilestone } from "common/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Activity } from "views/Dashboard/LearningContent/constants";
import { FaTimesCircle } from "react-icons/fa";
import { UserContext } from "contexts/UserContext";

const MilestoneTableRow: React.FC<{
  milestone: LearningMilestone;
  isReordering: boolean;
  onClick: () => void;
  setDeleteMilestone: React.Dispatch<
    React.SetStateAction<(() => Promise<void>) | null>
  >;
}> = ({ milestone, isReordering, onClick, setDeleteMilestone }) => {
  const { selectedCurriculum } = useContext(UserContext);
  const queryClient = useQueryClient();
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: milestone.milestoneID });

  const activityQuery = useQuery([QUERY_KEYS.activities], getActivities);

  const style = {
    transform: `translate(${transform?.x ?? 0}px, ${
      transform?.y ?? 0
    }px) scaleY(1)`,
    transition,
  };

  const learningContent = useMemo(() => {
    return milestone.activities
      .map((id) =>
        activityQuery.data ? activityQuery.data.find((a) => a.id === id) : []
      )
      .filter((a): a is Activity => !!a);
  }, [activityQuery.data, milestone.activities]);

  const deleteMilestoneMutation = useMutation(deleteMilestone, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.curriculums]);
    },
  });

  return (
    <Tr ref={setNodeRef} style={style}>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {milestone.name}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td w="100%">
        {milestone.description ? (
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {milestone.description}
          </Text>
        ) : (
          <Spacer w="max-content" />
        )}
      </Td>

      <Td>
        <VStack justify="center">
          {milestone.ageGroups.map((ag) => (
            <Badge
              bg={bgStatus}
              color={colorStatus}
              fontSize="12px"
              p="3px 10px"
              borderRadius="8px"
              key={ag}
            >
              {ag}
            </Badge>
          ))}
        </VStack>
      </Td>

      <Td>
        <VStack justify="center">
          {milestone.developmentAreas.map((area) => (
            <Badge
              bg={bgStatus}
              color={colorStatus}
              fontSize="12px"
              p="3px 10px"
              borderRadius="8px"
              key={area}
            >
              {area}
            </Badge>
          ))}
        </VStack>
      </Td>

      <Td>
        <VStack justify="center">
          {learningContent.map((lc) => (
            <Link
              key={`${milestone.milestoneID}${lc.id}`}
              href={`https://preschool.education/activity/${lc.id}`}
              target="_blank"
            >
              <Button fontSize="12px" borderRadius="8px">
                {lc.name}
              </Button>
            </Link>
          ))}
        </VStack>
      </Td>

      <Td p={0}>
        {isReordering ? (
          <IconButton
            {...attributes}
            {...listeners}
            icon={<Icon as={BiGridVertical} />}
            aria-label="reorder handle"
          />
        ) : (
          <HStack>
            <IconButton
              colorScheme="blue"
              onClick={onClick}
              icon={<Icon as={BiEdit} />}
              aria-label="edit button"
            />
            <IconButton
              colorScheme="red"
              onClick={() => {
                setDeleteMilestone(() => async () => {
                  if (!selectedCurriculum) return;

                  await deleteMilestoneMutation.mutateAsync({
                    ...milestone,
                    curriculumID: selectedCurriculum.curriculumID,
                  });
                  return;
                });
              }}
              icon={<Icon as={FaTimesCircle} />}
              aria-label="delete button"
            />
          </HStack>
        )}
      </Td>
    </Tr>
  );
};

export default MilestoneTableRow;
