import { Image, ImageProps } from "@chakra-ui/react";

import { baseURL } from "../common/axios";

const S3ImageRenderer: React.FC<{ fileName: string } & ImageProps> = ({
  fileName,
  ...imageProps
}) => {
  return (
    <Image
      src={`${baseURL}/api/s3/uploads/${fileName}`}
      alt=""
      {...imageProps}
    />
  );
};

export default S3ImageRenderer;
