import axios, { AxiosError, AxiosPromise, AxiosRequestConfig } from "axios";
import {
  applyAuthTokenInterceptor,
  clearAuthTokens,
  IAuthTokens,
  TokenRefreshRequest,
} from "axios-jwt";
import { dispatchOnUserChangeEvent } from "common/events";

import { AuthTokens } from "types/users";

export type RequestMethod = "get" | "put" | "post" | "delete" | "patch";

export interface CallAPIOptionsParams extends Record<string, unknown> {
  include?: string;
  limit?: number;
  offset?: number;
  order?: string;
  where?: {
    [key: string]: unknown;
  };
}

export type CallAPIOptions = AxiosRequestConfig & {
  params?: CallAPIOptionsParams;
  data?: unknown;
};

export interface APIResponse {
  data: unknown;
  errors: string;
  message: string;
}

function signOutUser() {
  clearAuthTokens();
  if (typeof window !== "undefined") {
    localStorage.setItem("user", "");
  }
  dispatchOnUserChangeEvent();
}

export const baseURL = process.env.REACT_APP_BASE_API_URL
  ? process.env.REACT_APP_BASE_API_URL
  : "http://localhost:8080";

const requestRefresh: TokenRefreshRequest = async (
  _refreshToken: string
): Promise<IAuthTokens | string> => {
  try {
    const response = await fetch(`${baseURL}/api/user/refresh`, {
      method: "POST",
      credentials: "include",
    });

    const { accessToken } = ((await response.json()) as {
      data: AuthTokens;
    }).data;

    return {
      accessToken: accessToken.token,
      refreshToken: accessToken.token,
    };
  } catch {
    signOutUser();
    return "";
  }
};

const axiosInstance = axios.create({ baseURL: baseURL, withCredentials: true });
const ssrAxiosInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

applyAuthTokenInterceptor(axiosInstance, {
  requestRefresh,
});

export const makeApiCall = (
  fullUrl: string,
  options: CallAPIOptions = {},
  method: RequestMethod = "get",
  ssr = false
): AxiosPromise<APIResponse> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { headers: rawHeaders, ...redactedOptions } = options;
  const headers = rawHeaders as Record<string, unknown>;
  const headerOptions = { headers };
  const fetcher = ssr ? ssrAxiosInstance : axiosInstance;

  const methodHandlers = {
    get: () => fetcher.get(fullUrl, options),
    put: () => fetcher.put(fullUrl, redactedOptions.data, headerOptions),
    post: () => fetcher.post(fullUrl, redactedOptions.data, headerOptions),
    patch: () => fetcher.patch(fullUrl, redactedOptions.data, headerOptions),
    delete: () => fetcher.delete(fullUrl, options),
  };

  return methodHandlers[method]() as AxiosPromise<APIResponse>;
};

export function makeFullUrlApiCall(
  endpoint: string,
  options?: CallAPIOptions,
  method: RequestMethod = "get",
  ssr = false
): AxiosPromise<APIResponse> {
  return makeApiCall(`/api/${endpoint}`, options, method, ssr);
}

export function isAxiosError(
  error: unknown
): error is AxiosError<{ errors: string | string[] }> {
  return (error as AxiosError).isAxiosError !== undefined;
}
