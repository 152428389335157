import {
  HStack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import { useQuery } from "@tanstack/react-query";
import { getAllChildProfile, QUERY_KEYS } from "common/api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { ChildProfile } from "../interfaces";
import FeedbackModal from "./FeedbackModal";
import ProgressTrackerTableRow from "./ProgressTrackerTableRow";

const ProgressTrackerTable: React.FC = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const disclosureProps = useDisclosure();

  const [childProfiles, setChildProfiles] = useState<ChildProfile[]>([]);
  const [childProfileToEdit, setChildProfileToEdit] = useState<ChildProfile>();

  const childProfilesQuery = useQuery(
    [QUERY_KEYS.childProfiles],
    getAllChildProfile
  );

  useEffect(() => {
    if (!childProfilesQuery.data) {
      return;
    }

    setChildProfiles(childProfilesQuery.data);
  }, [childProfilesQuery.data]);

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <FeedbackModal
        disclosureProps={disclosureProps}
        childProfile={childProfileToEdit}
      />

      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Child Profiles
        </Text>
      </CardHeader>
      <CardBody>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {["Name", "Birthday", ""].map((caption, idx) => {
                return (
                  <Th
                    color="gray.400"
                    key={idx}
                    ps={idx === 0 ? "0px" : undefined}
                  >
                    <HStack justifyContent="center" textAlign="center">
                      <Text>{caption}</Text>
                    </HStack>
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          <Tbody>
            {childProfiles.map((row) => {
              return (
                <ProgressTrackerTableRow
                  key={row.childID}
                  childProfile={row}
                  onClick={() => {
                    disclosureProps.onOpen();
                    setChildProfileToEdit(row);
                  }}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default ProgressTrackerTable;
