import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  ButtonGroup,
  IconButton,
  Flex,
  useEditableControls,
} from "@chakra-ui/react";

function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton
        icon={<CheckIcon />}
        {...getSubmitButtonProps()}
        aria-label="Confirm edit button"
      />
      <IconButton
        icon={<CloseIcon />}
        {...getCancelButtonProps()}
        aria-label="Close button"
      />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton
        size="sm"
        icon={<EditIcon />}
        {...getEditButtonProps()}
        aria-label="Edit button"
      />
    </Flex>
  );
}

export default EditableControls;
