import Tables from "views/Dashboard/LearningContent";
import Milestones from "views/Dashboard/Milestones";
import { StatsIcon } from "components/Icons/Icons";
import { ProgressTracker } from "views/Dashboard/ProgressTrackers";
import { Icon } from "@chakra-ui/react";
import { AiFillFileText, AiFillStar, AiOutlineUserAdd } from "react-icons/ai";
import { AdminManagement } from "views/Dashboard/AdminManagement";

export type Routes = ChildRoute | ParentRoute;

type ChildRoute = {
  path: string;
  name: string;
  icon: JSX.Element;
  component: () => JSX.Element;
  layout: string;
  collapse?: undefined;
  category?: undefined;
  views?: undefined;
  secondaryNavbar?: boolean;
};

type ParentRoute = {
  path?: undefined;
  icon?: undefined;
  component?: undefined;
  layout?: undefined;
  secondaryNavbar?: undefined;
  name: string;
  collapse: boolean;
  category: string;
  views: Routes[];
};

const dashRoutes: Routes[] = [
  {
    path: "/tables",
    name: "Learning content",
    icon: <Icon as={AiFillFileText} color="inherit" />,
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/milestones",
    name: "Milestones",
    icon: <Icon as={AiFillStar} color="inherit" />,
    component: Milestones,
    layout: "/admin",
  },
  {
    path: "/progress",
    name: "Progress Trackers",
    icon: <StatsIcon color="inherit" />,
    component: ProgressTracker,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "Admin Management",
    icon: <Icon as={AiOutlineUserAdd} color="inherit" />,
    component: AdminManagement,
    layout: "/admin",
  },
  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/signin",
  //       name: "Sign In",
  //       icon: <DocumentIcon color="inherit" />,
  //       component: SignIn,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/signup",
  //       name: "Sign Up",
  //       icon: <RocketIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       component: SignUp,
  //       layout: "/auth",
  //     },
  //   ],
  // },
];
export default dashRoutes;
