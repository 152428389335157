import {
  Curriculum,
  LearningMilestone,
} from "views/Dashboard/Milestones/interfaces";
import {
  Activity,
  ActivityObjective,
} from "views/Dashboard/LearningContent/constants";
import { makeFullUrlApiCall } from "./axios";
import { ChildProfile } from "views/Dashboard/ProgressTrackers/interfaces";
import { User } from "types/users";

export const QUERY_KEYS = {
  activities: "activities",
  curriculums: "curriculums",
  childProfiles: "childProfiles",
  admins: "admins",
};

export async function getActivities(): Promise<Activity[]> {
  const response = await makeFullUrlApiCall("activity");
  if (response.status !== 200) {
    return [];
  }

  const activities = (response.data.data as Activity[])
    .map((activity) => {
      return {
        ...activity,
        ageGroups: activity.ageGroups.sort(),
      };
    })
    .sort((a, b) => a.contentType.localeCompare(b.contentType));

  return activities;
}

export async function addActivity(
  data: Omit<Activity, "objectives"> & {
    objectives: Array<Omit<ActivityObjective, "activityObjectiveID">>;
  }
) {
  const response = await makeFullUrlApiCall(
    "activity",
    {
      data,
    },
    "post"
  );

  return response;
}

export async function updateActivity(data: Activity) {
  const response = await makeFullUrlApiCall(
    "activity",
    {
      data: {
        ...data,
        activityID: data.id,
      },
    },
    "patch"
  );

  return response;
}

export async function getCurriculums(): Promise<Curriculum[]> {
  const response = await makeFullUrlApiCall("activity/curriculum");
  if (response.status !== 200) {
    return [];
  }

  const curriculum = response.data.data as Curriculum[];

  return curriculum;
}

export async function addMilestone(
  data: Omit<LearningMilestone, "milestoneID"> & {
    curriculumID: string;
    activityIDs: number[];
  }
) {
  const response = await makeFullUrlApiCall(
    "activity/milestone",
    {
      data,
    },
    "post"
  );

  return response;
}

export async function updateMilestone(
  data: LearningMilestone & { curriculumID: string }
) {
  const response = await makeFullUrlApiCall(
    "activity/milestone",
    {
      data,
    },
    "patch"
  );

  return response;
}

export async function deleteMilestone(
  data: LearningMilestone & { curriculumID: string }
) {
  const response = await makeFullUrlApiCall(
    "activity/milestone",
    {
      data,
    },
    "delete"
  );

  return response;
}

export async function reorderMilestones(data: {
  milestoneIDs: string[];
  curriculumID: string;
}) {
  const response = await makeFullUrlApiCall(
    "activity/curriculum/reorder",
    {
      data,
    },
    "patch"
  );

  return response;
}

export async function getAllChildProfile(): Promise<ChildProfile[]> {
  const response = await makeFullUrlApiCall("childprofile/all");
  if (response.status !== 200) {
    return [];
  }

  const profiles = response.data.data as ChildProfile[];

  return profiles.reverse();
}

export async function getAdmins(): Promise<User[]> {
  const response = await makeFullUrlApiCall("user?role=Admin");
  if (response.status !== 200) {
    return [];
  }

  const profiles = response.data.data as User[];

  return profiles;
}

export async function updateUser(data: Partial<User> & { userId: string }) {
  const response = await makeFullUrlApiCall(
    `user/${data.userId}`,
    {
      data,
    },
    "patch"
  );

  return response;
}

export async function feedbackMilestoneProgressTracker(data: {
  milestoneProgressTrackerID: string;
  expertFeedback: string;
}) {
  const response = await makeFullUrlApiCall(
    "progresstracker/milestoneprogresstracker",
    {
      data,
    },
    "patch"
  );

  return response;
}
