import { Icon, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

export function ItemContent(props: {
  icon: IconType;
  boldInfo: string;
  info?: string;
}) {
  const notificationColor = useColorModeValue("gray.700", "white");
  return (
    <>
      <Icon
        as={props.icon}
        boxSize="32px"
        borderRadius="12px"
        p="4px"
        me="16px"
      />
      <Flex flexDirection="column">
        <Text fontSize="14px" mb="5px" color={notificationColor}>
          <Text fontWeight="bold" fontSize="14px" as="span">
            {props.boldInfo}
          </Text>
          {props.info ?? ""}
        </Text>
      </Flex>
    </>
  );
}
