import { BsHandThumbsDownFill, BsHandThumbsUpFill } from "react-icons/bs";

import { Icon, IconProps, Stack, StackProps, Text } from "@chakra-ui/react";

export type LikeDislikeValue = "like" | "dislike" | "none";

const LikeDislikeButtonGroup: React.FC<
  {
    label: string;
    value: LikeDislikeValue | null | undefined;
    iconProps?: IconProps;
  } & StackProps
> = ({ label, value, iconProps, ...stackProps }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      fontSize="sm"
      fontWeight="normal"
      {...stackProps}
    >
      <Text>{label}</Text>
      {value !== "none" ? (
        <Icon
          as={value === "like" ? BsHandThumbsUpFill : BsHandThumbsDownFill}
          color={value === "like" ? "lightgreen" : "red"}
          boxSize="20px"
          {...iconProps}
        />
      ) : (
        <Text>None</Text>
      )}
    </Stack>
  );
};

export default LikeDislikeButtonGroup;
