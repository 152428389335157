import React, { useContext } from "react";
import { FaGoogle } from "react-icons/fa";
import SocialLogin, { Props } from "react-social-login";

import { Button, CircularProgress, useToast } from "@chakra-ui/react";

import {
  AuthTokens,
  ISocialLoginType,
  SocialLoginType,
  User,
  UserRole,
} from "types/users";
import { UserContext } from "contexts/UserContext";
import { makeFullUrlApiCall } from "common/axios";

interface SocialButtonBaseProps {
  icon: React.ReactElement;
  label: string;
  [key: string]: unknown;
}

async function socialLogin(
  data: { profile: { email: string; id: string } },
  socialLoginType: ISocialLoginType,
  setUser: (user: User, tokens: AuthTokens) => void,
  toast: ReturnType<typeof useToast>
) {
  const response = await makeFullUrlApiCall(
    "user/social-login",
    {
      data: {
        email: data.profile.email,
        password: data.profile.id,
        socialLoginType,
      },
    },
    "post"
  );

  const { accessToken, ...user } = response.data.data as AuthTokens &
    User & { isNewUser: boolean };

  if (user.role !== UserRole.admin) {
    toast({
      title: "Error",
      description: `You are not an admin!`,
      status: "error",
      duration: 4000,
      isClosable: true,
    });
    return false;
  }

  setUser(user as User, { accessToken });
  return true;
}

const SocialButton: React.FC<
  SocialButtonBaseProps & { triggerLogin: () => void }
> = ({ icon, label, triggerLogin, ...restProps }) => (
  <Button
    fontSize="13px"
    color="white"
    w="100%"
    leftIcon={icon}
    boxShadow="xl"
    onClick={triggerLogin}
    {...restProps}
  >
    {label}
  </Button>
);

const SocialButtonLoader: React.FC = () => (
  <CircularProgress isIndeterminate size="20px" />
);

const TransformedSocialButton = SocialLogin(
  SocialButton,
  SocialButtonLoader
) as React.FC<Props & SocialButtonBaseProps>;

interface SocialButtonProps {
  closeModal: () => void;
}

export const GoogleButton: React.FC<SocialButtonProps> = (props) => {
  const { setUser } = useContext(UserContext);
  const toast = useToast();
  const appId = process.env.REACT_APP_GOOGLE_OAUTH_ID;
  if (!appId) {
    console.error("No google oauth ID was supplied");
    return null;
  }
  return (
    <TransformedSocialButton
      appId={process.env.REACT_APP_GOOGLE_OAUTH_ID as string}
      provider="google"
      bgColor="#4285F4"
      icon={<FaGoogle />}
      label="Login with Google"
      onLoginSuccess={async (user: {
        profile: { email: string; id: string };
      }) => {
        const success = await socialLogin(
          user,
          SocialLoginType.google,
          setUser,
          toast
        );
        if (success) {
          props.closeModal();
        }
      }}
      onLoginFailure={(error) => console.log(error)}
    />
  );
};

export default SocialButton;
