import { baseURL } from "common/axios";
import { Image } from "@chakra-ui/react";

export function generateS3ImageUrl(fileName: string): string {
  return `${baseURL}/api/s3/uploads/${fileName}`;
}

export function generateChildProfileImgSrc(
  profileImg: string,
  gender: string
): string {
  return profileImg
    ? profileImg.startsWith("/avatar")
      ? profileImg
      : generateS3ImageUrl(profileImg)
    : gender === "Female"
    ? "/avatars/daughter.png"
    : "/avatars/son_1.png";
}

const ChildProfileImageRenderer: React.FC<{
  profileImg?: string;
  gender?: string;
}> = ({ profileImg, gender }) => (
  <Image
    src={generateChildProfileImgSrc(profileImg ?? "", gender ?? "Male")}
    width="55px"
    height="55px"
    alt="Child profile pic"
  />
);

export default ChildProfileImageRenderer;
