import {
  Center,
  HStack,
  Icon,
  IconButton,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { BiEdit } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { ChildProfile } from "../interfaces";
import ChildProfileImageRenderer from "components/ChildProfileImageRenderer";
import { getAge, getAgeMonths } from "common/utils";

const ProgressTrackerTableRow: React.FC<{
  childProfile: ChildProfile;
  onClick: () => void;
}> = ({ childProfile, onClick }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [childAge, setChildAge] = useState("0");

  useEffect(() => {
    const birthdate = new Date(childProfile.birthday);
    const yearDiff = getAge(birthdate);
    const monthDiff = getAgeMonths(birthdate);

    if (yearDiff > 0) {
      const monthsInCurrentYear = monthDiff - yearDiff * 12;
      setChildAge(
        `${yearDiff} year${yearDiff !== 1 ? "s" : ""} ${
          monthsInCurrentYear > 0
            ? `${monthsInCurrentYear} month${
                monthsInCurrentYear > 1 ? "s" : ""
              }`
            : ""
        }`
      );
      return;
    }

    setChildAge(`${monthDiff} month${monthDiff !== 1 ? "s" : ""}`);
  }, [childProfile]);

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <HStack spacing={8} py=".8rem" minWidth="100%" flexWrap="nowrap">
          <ChildProfileImageRenderer
            profileImg={childProfile.profileImg}
            gender={childProfile.gender}
          />
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {childProfile.childName}
          </Text>
        </HStack>
      </Td>

      <Td>
        <Center>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {childAge}
          </Text>
        </Center>
      </Td>

      <Td p={0}>
        <HStack>
          <IconButton
            colorScheme="blue"
            onClick={onClick}
            icon={<Icon as={BiEdit} />}
            aria-label="edit button"
          />
        </HStack>
      </Td>
    </Tr>
  );
};

export default ProgressTrackerTableRow;
