import {
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Image,
  VStack,
  Icon,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import S3ImageRenderer from "components/S3ImageRenderer";
import {
  FaFileWord,
  FaFortAwesome,
  FaRegFileAlt,
  FaTabletAlt,
  FaVideo,
} from "react-icons/fa";
import {
  ContentType,
  IContentType,
} from "views/Dashboard/LearningContent/constants";
import { useUrlQuery } from "common/hooks";

const CONTENT_TYPE_ICONS = {
  [ContentType.worksheet]: FaFileWord,
  [ContentType.game]: FaFortAwesome,
  [ContentType.lessonPlan]: FaTabletAlt,
  [ContentType.video]: FaVideo,
  [ContentType.resource]: FaRegFileAlt,
};

const TablesTableRow: React.FC<{
  id: number;
  name: string;
  description: string;
  contentType: string;
  ageGroups: string[];
  developmentAreas: string[];
  domain: string;
  date: string;
  media: string;
  onClick: () => void;
}> = ({
  id,
  name,
  description,
  domain,
  contentType,
  ageGroups,
  developmentAreas,
  media,
  onClick,
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const query = useUrlQuery();
  const rowRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (rowRef.current && String(id) === query.get("id")) {
      rowRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [id, query]);

  return (
    <Tr id={String(id)} ref={rowRef}>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {media.includes("http") ? (
            <Image
              src={media}
              alt=""
              minW="50px"
              boxSize="50px"
              objectFit="contain"
              objectPosition="top center"
              position="relative"
              mr="20px"
            />
          ) : (
            <S3ImageRenderer
              fileName={media}
              boxSize="50px"
              minW="50px"
              objectFit="contain"
              objectPosition="top center"
              position="relative"
              mr="20px"
            />
          )}
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            {/* <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {email}
            </Text> */}
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {domain}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {description}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Tag
          // bg={contentType === "Online" ? "green.400" : bgStatus}
          color="black"
          fontSize="16px"
          borderRadius="full"
          size="lg"
        >
          <Icon as={CONTENT_TYPE_ICONS[contentType as IContentType]} mr={2} />
          <TagLabel>{contentType}</TagLabel>
        </Tag>
      </Td>
      <Td>
        <VStack justify="center">
          {developmentAreas.map((area) => (
            <Badge
              bg={bgStatus}
              color={colorStatus}
              fontSize="12px"
              p="3px 10px"
              borderRadius="8px"
              key={area}
            >
              {area}
            </Badge>
          ))}
        </VStack>
        {/* <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {moment(date).format("d MMM YY")}
        </Text> */}
      </Td>
      <Td>
        <VStack justify="center">
          {ageGroups.map((ag) => (
            <Badge
              bg={bgStatus}
              color={colorStatus}
              fontSize="12px"
              p="3px 10px"
              borderRadius="8px"
              key={ag}
            >
              {ag}
            </Badge>
          ))}
        </VStack>
        {/* <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {moment(date).format("d MMM YY")}
        </Text> */}
      </Td>
      <Td>
        <Button colorScheme="blue" onClick={onClick}>
          Edit
        </Button>
      </Td>
    </Tr>
  );
};

export default TablesTableRow;
