import mime from "mime";

function getFileMimeType(file: File): string {
  return file.type || (mime.getType(file.name) as string);
}

export const uploadFile: (file: File, path: string) => Promise<string> = async (
  file,
  path
) => {
  const signedUrlResponse = await fetch(
    `s3/sign?objectName=${file.name}&contentType=${encodeURIComponent(
      getFileMimeType(file)
    )}&path=${encodeURIComponent(path)}`
  );

  if (signedUrlResponse.status !== 200) {
    return "";
  }

  const signedUrlResponseData = ((await signedUrlResponse.json()) as unknown) as {
    signedUrl: string;
    filename: string;
  };

  const response = await fetch(signedUrlResponseData.signedUrl, {
    method: "PUT",
    body: file,
  });

  if (response.status !== 200) {
    console.error("Failed to upload", response);
    return "";
  }

  return signedUrlResponseData.filename;
};

export enum SkillEnums {
  dailyActivity = "Daily Activity",
  subject = "Subject",
  developmentAreas = "Development Areas",
  activityType = "Activity Type",
  motorSkills = "Motor Skills",
  numeracySkills = "Numeracy Skills",
  literacySkills = "Literacy Skills",
  socialAndEmotionalSkills = "Social & Emotional Skills",
  cognitiveSkills = "Cognitive Skills",
  creativeSkills = "Creative Skills",
}

export type Tags = Record<SkillEnums, string[]>;

export type ActivityObjective = {
  activityObjectiveID: string;
  name: string;
  description: string;
};

export interface Activity {
  id: number;
  name: string;
  contentType: string;
  description: string;
  media: string;
  hardwareRequired: boolean;
  ageGroups: string[];
  developmentAreas: string[];
  steamSubjects: string[];
  activityTypes: string[];
  resources: string;
  tags: Tags;
  createdAt: string;
  objectives: ActivityObjective[];
}

export const ContentType = {
  resource: "Resource",
  worksheet: "Worksheet",
  lessonPlan: "Lesson Plan",
  game: "Game",
  video: "Video",
} as const;

export type IContentType = typeof ContentType[keyof typeof ContentType];

export const ActivityType = {
  parallel: "Parallel",
  guided: "Guided",
  collaborative: "Collaborative",
  independent: "Independent",
} as const;

export type IActivityType = typeof ActivityType[keyof typeof ActivityType];

export const SteamSubject = {
  science: "Science",
  technology: "Technology",
  engineering: "Engineering",
  arts: "Arts",
  mathematics: "Mathematics",
} as const;

export type ISteamSubject = typeof SteamSubject[keyof typeof SteamSubject];

export const DevelopmentArea = {
  cognitive: "Cognitive Skills",
  motor: "Motor Skills",
  numeracy: "Numeracy Skills",
  literacy: "Literacy Skills",
  creative: "Creative Skills",
  socialEmotional: "Social & Emotional Skills",
  life: "Life Skills",
} as const;

export type IDevelopmentArea = typeof DevelopmentArea[keyof typeof DevelopmentArea];

export const AgeGroup = {
  twoThree: "2 to 3",
  threeFour: "3 to 4",
  fourFive: "4 to 5",
  fiveSix: "5 to 6",
  sixPlus: "6+",
} as const;

export type IAgeGroup = typeof AgeGroup[keyof typeof AgeGroup];

export const reverseAgeGroupMatch = {
  [AgeGroup.twoThree]: 2,
  [AgeGroup.threeFour]: 3,
  [AgeGroup.fourFive]: 4,
  [AgeGroup.fiveSix]: 5,
  [AgeGroup.sixPlus]: 6,
};

export const cognitiveSkillsItem = [
  "Logic",
  "Problem Solving",
  "Memory and Attention",
  "Causation",
  "Awareness",
];

export const motorSkillsItem = [
  "Fine motor",
  "Gross motor",
  "Balancing",
  "Writing + Drawing",
];

export const creativeSkillsItem = [
  "Imagination",
  "Exploration",
  "Aesthetic Expression",
  "Visual Recognition",
  "Patterns",
];

export const socialAndEmotionalSkillsItem = [
  "Empathy",
  "Self-awareness and care",
  "Emotional regulation",
  "Community awareness",
  "Safety and security",
  "Cooperation",
];

export const numeracySkillsItem = [
  "Counting",
  "Problem solving",
  "Numerals",
  "Geometry",
  "Estimating",
  "Measuring",
  "Time",
  "Sequences",
  "Spatial orientation",
];

export const literacySkillsItem = [
  "Reading",
  "Writing",
  "Grammar",
  "Alphabets",
  "Words",
  "Conversation",
];

export const DevelopmentAreaItems = {
  [DevelopmentArea.cognitive]: cognitiveSkillsItem,
  [DevelopmentArea.motor]: motorSkillsItem,
  [DevelopmentArea.numeracy]: numeracySkillsItem,
  [DevelopmentArea.literacy]: literacySkillsItem,
  [DevelopmentArea.socialEmotional]: socialAndEmotionalSkillsItem,
  [DevelopmentArea.creative]: creativeSkillsItem,
  [DevelopmentArea.life]: [],
};
