import chunk from "lodash.chunk";
import { PropsWithChildren } from "react";
import {
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";

function MultipleCategoryButton<
  T extends string,
  IFormInputs extends FieldValues,
  FK extends Path<IFormInputs>
>({
  inputType,
  watchValue,
  setValue,
  fieldKey,
  color = "primary.yellow",
  singleOption,
}: PropsWithChildren<
  | {
      inputType: T;
      watchValue: T[];
      setValue: (key: FK, value: T[]) => void;
      fieldKey: FK;
      color?: string;
      singleOption: false;
    }
  | {
      inputType: T;
      watchValue: T;
      setValue: (key: FK, value: T) => void;
      fieldKey: FK;
      color?: string;
      singleOption: true;
    }
>) {
  const isSelected = singleOption
    ? watchValue === inputType
    : watchValue.includes(inputType);

  return (
    <Button
      width="100%"
      borderWidth={isSelected ? "3px" : ""}
      borderColor="blue.300"
      backgroundColor={color}
      fontSize="13px"
      onClick={() => {
        if (singleOption) {
          setValue(fieldKey, inputType);
        } else {
          setValue(
            fieldKey,
            isSelected
              ? watchValue.filter((val) => val !== inputType)
              : [...watchValue, inputType]
          );
        }
      }}
    >
      {inputType}
    </Button>
  );
}

function MultipleCategoryFormField<
  T extends string,
  U extends T,
  IFormInputs extends FieldValues,
  FK extends Path<IFormInputs>
>(
  props: PropsWithChildren<
    | {
        error?: FieldError[];
        label: string;
        options: Record<string, T>;
        watchValue: U[];
        setValue: (key: FK, value: T[]) => void;
        fieldKey: FK;
        buttonColor?: string;
        register: UseFormRegister<IFormInputs>;
        singleOption: false;
      }
    | {
        error?: FieldError[];
        label: string;
        options: Record<string, T>;
        watchValue: U;
        setValue: (key: FK, value: T) => void;
        fieldKey: FK;
        buttonColor?: string;
        register: UseFormRegister<IFormInputs>;
        singleOption: true;
      }
  >
) {
  const { error, label, options, fieldKey, register } = props;
  const optionValues = Object.values(options);

  return (
    <FormControl isInvalid={!!error} my="40px">
      <FormLabel>{label}</FormLabel>
      <VStack spacing={5}>
        {chunk(optionValues, optionValues.length % 3 === 0 ? 3 : 2).map(
          (chunkedOptions) => (
            <HStack key={chunkedOptions[0]} w="100%">
              {chunkedOptions.map((option) => (
                <MultipleCategoryButton
                  key={option}
                  inputType={option}
                  {...props}
                />
              ))}
            </HStack>
          )
        )}
      </VStack>
      <Input display="none" id={fieldKey} {...register(fieldKey)} />

      <FormErrorMessage>{error && error[0].message}</FormErrorMessage>
    </FormControl>
  );
}

export default MultipleCategoryFormField;
