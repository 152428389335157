import React, { useState } from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon, SmallCloseIcon } from "@chakra-ui/icons";

type Props = {
  setSearchKeyword: (v: string) => void;
} & InputGroupProps;

export const SearchBar: React.FC<Props> = ({
  setSearchKeyword,
  children: _children,
  ...inputGroupProps
}) => {
  const [keyword, setKeyword] = useState("");
  const mainTeal = useColorModeValue("teal.300", "teal.300");
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("white", "gray.800");

  return (
    <InputGroup
      bg={inputBg}
      borderRadius="15px"
      _focus={{
        borderColor: { mainTeal },
      }}
      _active={{
        borderColor: { mainTeal },
      }}
      {...inputGroupProps}
    >
      <InputLeftElement
        children={
          <IconButton
            bg="inherit"
            borderRadius="inherit"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            _hover={{}}
            icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
            aria-label=""
          />
        }
      />
      <Input
        fontSize="xs"
        py="11px"
        placeholder="Type here..."
        borderRadius="inherit"
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSearchKeyword(keyword);
          }
        }}
      />
      <InputRightElement
        children={
          <IconButton
            icon={
              <SmallCloseIcon
                boxSize="16px"
                color="gray.400"
                _hover={{
                  color: "red",
                }}
              />
            }
            aria-label="Clear search"
            bg="inherit"
            borderRadius="inherit"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            _hover={{}}
            onClick={() => {
              setKeyword("");
              setSearchKeyword("");
            }}
          />
        }
      />
    </InputGroup>
  );
};
