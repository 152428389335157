import React from "react";
import { Button, ButtonProps, Text } from "@chakra-ui/react";
import { baseURL } from "../../../../common/axios";

const LearningContentDownloader: React.FC<{
  fileName: string;
  label?: string;
  buttonProps?: ButtonProps;
}> = ({ fileName, buttonProps, label = "Download" }) => {
  return (
    <a
      href={`${baseURL}/api/s3/uploads/learning-content/${fileName}`}
      download
      target="_blank"
      rel="noreferrer"
    >
      <Button
        width="200px"
        borderRadius={20}
        boxShadow="2xl"
        colorScheme="green"
        marginRight="5px"
        marginBottom="5px"
        alignItems="center"
        {...buttonProps}
      >
        <Text color="black">{label}</Text>
      </Button>
    </a>
  );
};

export default LearningContentDownloader;
