import { AxiosResponse } from "axios";
import React, { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { AuthTokens, User, UserRole } from "types/users";
import { APIResponse, isAxiosError, makeFullUrlApiCall } from "common/axios";
import { UserContext } from "contexts/UserContext";

interface IFormInputs {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .email("This is not a valid email")
      .required("Please login using your email"),
    password: yup.string().required("Please type your password"),
  })
  .required();

interface Props {
  closeModal: () => void;
}

const LoginForm: React.FC<Props> = ({ closeModal }) => {
  const { setUser } = useContext(UserContext);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });
  const toast = useToast();

  const handleLoginSuccess = useCallback(
    (response: AxiosResponse<APIResponse>) => {
      const { accessToken, ...user } = response.data.data as AuthTokens & User;

      if (user.role !== UserRole.admin) {
        toast({
          title: "Error",
          description: `You are not an admin!`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }

      setUser(user as User, { accessToken });
      closeModal();
    },
    [closeModal, setUser, toast]
  );

  const handleLoginError = (error: unknown) => {
    if (!isAxiosError(error)) {
      console.log(error);
      return;
    }

    const errors = error.response?.data?.errors as string | string[];

    if (Array.isArray(errors)) {
      console.log(errors);
    } else if (errors.includes("Email")) {
      setError("email", { message: "This email is invalid" });
      return;
    } else if (errors.includes("Password")) {
      setError("password", { message: "This password is invalid" });
      return;
    } else {
      setError("password", {
        message: "Sorry! We received an unexpected error",
      });
    }

    return;
  };

  const onSubmit = async (data: IFormInputs) => {
    await makeFullUrlApiCall(
      "user/login",
      {
        data,
      },
      "post"
    )
      .then(handleLoginSuccess)
      .catch(handleLoginError);
  };

  return (
    <>
      <Flex
        flexDir="column"
        w="100%"
        textAlign="center"
        sx={{
          "input, textarea": { bgColor: "white", borderRadius: "10px" },
          "input::placeholder": { color: "#9F9F9F" },
          form: { w: "100%" },
        }}
        alignItems="center"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={!!errors.email}>
            <Input
              id="email"
              type="email"
              placeholder="Email"
              {...register("email")}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.password} my="15px">
            <Input
              id="password"
              type="password"
              placeholder="Password"
              {...register("password")}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            bgColor="#1bc2ef"
            color="white"
            fontSize="14px"
            borderRadius="23px"
            p="14px 32px"
            mt="35px"
            maxW="170px"
            boxShadow="lg"
            type="submit"
            isLoading={isSubmitting}
          >
            Login
          </Button>
        </form>
      </Flex>
    </>
  );
};

export default LoginForm;
