import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAdmins, QUERY_KEYS, updateUser } from "common/api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import AdminManagementModal from "./AdminManagementModal";
import AdminManagementTableRow from "./AdminManagementTableRow";
import { User, UserRole } from "types/users";
import { AiOutlineClose } from "react-icons/ai";

const AdminManagementTable: React.FC = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const disclosureProps = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const [admins, setAdmins] = useState<User[]>([]);
  const [adminToDelete, setAdminToDelete] = useState<User>();

  const queryClient = useQueryClient();
  const adminQuery = useQuery([QUERY_KEYS.admins], getAdmins);
  const updateUserMutation = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.admins]);
    },
  });

  useEffect(() => {
    if (!adminQuery.data) {
      return;
    }

    setAdmins(adminQuery.data);
  }, [adminQuery.data]);

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete admin
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  if (!adminToDelete) {
                    return;
                  }
                  onClose();
                  updateUserMutation.mutateAsync({
                    userId: adminToDelete.userId,
                    role: UserRole.parent,
                  });
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AdminManagementModal disclosureProps={disclosureProps} />

      <CardHeader p="6px 0px 22px 0px">
        <HStack w="100%" pr={2}>
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Admins
          </Text>
          <Spacer />
          <Button
            onClick={() => {
              disclosureProps.onOpen();
            }}
          >
            Add admin
          </Button>
        </HStack>
      </CardHeader>
      <CardBody>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {["Name", "Email", "Login Type", "Role", ""].map(
                (caption, idx) => {
                  return (
                    <Th
                      color="gray.400"
                      key={idx}
                      ps={idx === 0 ? "0px" : undefined}
                    >
                      <HStack justifyContent="center" textAlign="center">
                        <Text>{caption}</Text>
                      </HStack>
                    </Th>
                  );
                }
              )}
            </Tr>
          </Thead>

          <Tbody>
            {admins.map((row) => {
              return (
                <AdminManagementTableRow
                  key={row.userId}
                  admin={row}
                  actionButton={
                    <IconButton
                      colorScheme="red"
                      onClick={() => {
                        onOpen();
                        setAdminToDelete(row);
                      }}
                      icon={<Icon as={AiOutlineClose} />}
                      aria-label="edit button"
                    />
                  }
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default AdminManagementTable;
