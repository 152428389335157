import { useDisclosure } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { clearAuthTokens, setAuthTokens } from "axios-jwt";
import { QUERY_KEYS, getCurriculums } from "common/api";
import {
  dispatchOnUserChangeEvent,
  ON_USER_CHANGE_EVENT_KEY,
} from "common/events";
import { getStandardCurriculum } from "common/utils";
import React, { useCallback, useEffect, useState } from "react";

import { AuthTokens, User } from "types/users";
import { Curriculum } from "views/Dashboard/Milestones/interfaces";

interface IUserContext {
  user: User | null;
  setUser: (user: User, tokens?: AuthTokens) => void;
  deleteUser: () => void;
  isLoggedIn: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  curriculums: Curriculum[] | undefined;
  selectedCurriculum: Curriculum | undefined;
  setSelectedCurriculum: React.Dispatch<
    React.SetStateAction<Curriculum | undefined>
  >;
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  setUser: () => {
    throw new Error("setUser is not defined");
  },
  deleteUser: () => {
    throw new Error("deleteUser is not defined");
  },
  isLoggedIn: false,
  isOpen: false,
  onOpen: () => {
    throw new Error("onOpen is not defined");
  },
  onClose: () => {
    throw new Error("onClose is not defined");
  },
  curriculums: [],
  selectedCurriculum: undefined,
  setSelectedCurriculum: () => {
    throw new Error("setSelectedCurriculum is not defined");
  },
});

function setUserInLocalStorage(user: User) {
  if (typeof window !== "undefined") {
    localStorage.setItem("user", JSON.stringify(user));
  }
}

function deleteUserInLocalStorage() {
  if (typeof window !== "undefined") {
    localStorage.setItem("user", "");
  }
}

function getUserInLocalStorage(): User | null {
  if (typeof window !== "undefined") {
    const stringifiedUser = localStorage.getItem("user");
    return stringifiedUser ? (JSON.parse(stringifiedUser) as User) : null;
  }

  return null;
}

const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState(getUserInLocalStorage());
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(user));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCurriculum, setSelectedCurriculum] = useState<Curriculum>();
  const curriculumQuery = useQuery([QUERY_KEYS.curriculums], getCurriculums);

  function saveUser(user: User, tokens?: AuthTokens) {
    setUserInLocalStorage(user);
    tokens && saveAuthToken(tokens);
    dispatchOnUserChangeEvent();
  }

  function removeUser() {
    clearAuthTokens();
    deleteUserInLocalStorage();
    dispatchOnUserChangeEvent();
  }

  function saveAuthToken(tokens: AuthTokens) {
    setAuthTokens({
      accessToken: tokens.accessToken.token,
      refreshToken: tokens.accessToken.token,
    });
  }

  const checkUserData = useCallback(() => {
    const user = getUserInLocalStorage();
    setUser(user);
    setIsLoggedIn(!!user);
  }, []);

  useEffect(() => {
    document.addEventListener(ON_USER_CHANGE_EVENT_KEY, checkUserData, false);

    return () => {
      document.removeEventListener(
        ON_USER_CHANGE_EVENT_KEY,
        checkUserData,
        false
      );
    };
  }, [checkUserData]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    if (!curriculumQuery.data) {
      return;
    }

    const standardCurriculum = getStandardCurriculum(curriculumQuery.data);

    if (!standardCurriculum) {
      return;
    }

    setSelectedCurriculum(
      (sc) =>
        curriculumQuery.data.find((c) => sc?.curriculumID === c.curriculumID) ??
        standardCurriculum
    );
  }, [curriculumQuery.data, isLoggedIn]);

  if (typeof window === "undefined") {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        user: user,
        setUser: saveUser,
        deleteUser: removeUser,
        isLoggedIn: isLoggedIn,
        isOpen,
        onClose,
        onOpen,
        setSelectedCurriculum,
        selectedCurriculum,
        curriculums: curriculumQuery.data,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
